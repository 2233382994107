thBlocs.add("sliderCover", function () {
    var sliderHomepage = $(".header-homepage--has-slider, .page-header--has-slider");

    if (sliderHomepage.length) {
        var slider = $(".slick-carousel", sliderHomepage);
        var sliderDuration = parseInt(slider.data('autoplay'));
        var sliderProgressBar = $(".th-slider-timebar-bar", sliderHomepage);

        if(sliderProgressBar.length > 0){
            updateProgressBarSliderThematique(sliderProgressBar, sliderDuration);
        }

        // Au change du slider, on mets à jour la progressbar
        slider.on('afterChange', function (e, el, index) {
            e.stopPropagation();

            if(sliderProgressBar.length > 0) {
                updateProgressBarSliderThematique(sliderProgressBar, sliderDuration);
            }
        });
    }

    function updateProgressBarSliderThematique(progressSpan, sliderDuration) {
        // Reset de la progressbar
        $(progressSpan).stop();
        progressSpan[0].style.width = "0%";

        // Animation de la progressbar
        $(progressSpan).animate({width: '100%'}, {
            duration: sliderDuration,
            easing: 'linear'
        });
    }
});