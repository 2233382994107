var whereIsTheSearchBar = 'inmap';

thBlocs.add('pagecarteinte', function () {
    if ($('.page-carte').length > 0) {

        whereIsTheSearchBar = 'inmap';
        replaceSearchCityBarAlgoliaPageCarte();

        var markerTarget = th_maps.createMarkerTarget();
        var macarte = th_maps.getMap($('.page-carte .map .maps').get(0));
        if (!macarte) {
            macarte = th_maps.initSingleMap($('.page-carte .map .maps').get(0));
        }

        var el = document.querySelector('#algolia-city-search');
        var geocodingResults = document.querySelector(".algolia-place__results");
        var geocodingRequest = 0;
        var inputUnfocused = null;
        var currentValue = "";

        if (el && geocodingResults) {
            el.addEventListener("input", function () {
                if (this.value !== currentValue && this.value !== "") {
                    currentValue = this.value;
                    var _self = this;

                    geocodingRequest++;
                    var ajaxCount = geocodingRequest;

                    $.get("https://data.geopf.fr/geocodage/completion?text=" + this.value + "&type=StreetAddress,PositionOfInterest&terr=METROPOLE&maximumResponses=5", function (res) {
                        if (ajaxCount === geocodingRequest) {
                            geocodingResults.innerHTML = "";

                            if (res && res.results && res.results.length) {
                                $.each(res.results, function (index, value) {
                                    if (value.x !== 0 && value.y !== 0) {
                                        var el = document.createElement("li");

                                        var btn = document.createElement("button");
                                        btn.setAttribute("data-lat", value.y);
                                        btn.setAttribute("data-lng", value.x);
                                        btn.setAttribute("data-adresse", value.fulltext);
                                        btn.textContent = value.fulltext;

                                        btn.addEventListener("click", function (e) {
                                            e.preventDefault();

                                            // Mise à jour de la value du champ recherche
                                            _self.value = value.fulltext;

                                            macarte.flyTo({lat: value.y, lng: value.x}, 12);
                                            markerTarget.setLatLng({lat: value.y, lng: value.x});
                                            macarte.addLayer(markerTarget);
                                            geocodingResults.innerHTML = "";
                                        });

                                        el.appendChild(btn);
                                        geocodingResults.appendChild(el);
                                    }
                                });
                            }
                        }
                    });
                }
            });

            el.addEventListener("focus", function () {
                if (this.value && inputUnfocused) {
                    this.value = "";
                }
            });

            el.addEventListener("blur", function () {
                if (!inputUnfocused) {
                    inputUnfocused = true
                }

                if (!this.value) {
                    geocodingResults.innerHTML = "";
                }
            });

            $('.geo-loc').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (lastValueGeo === null) {
                    geoloc.getGeoloc(function (coord) {
                        el.value = thLang.get('maposition');
                        lastValueGeo = thLang.get('maposition');
                        $('.geo-loc > span').attr('class', 'icon-ico-gallery-cross');
                        macarte.flyTo(coord, 12);
                        markerTarget.setLatLng(coord);
                        macarte.addLayer(markerTarget);
                    });
                } else {
                    macarte.removeLayer(markerTarget);
                    lastValueGeo = null;
                    el.value = '';
                    $('.geo-loc > span').attr('class', 'icon-ico-position');
                }
            });
        }


        $('form.content input[type="checkbox"]', '#overlay-cartemenu').on('change', function () {
            setTimeout(carteInteRefresh, 250);
        });


        setTimeout(carteInteRefresh, 500);

    }
});

$(window).on('resize', replaceSearchCityBarAlgoliaPageCarte);

function replaceSearchCityBarAlgoliaPageCarte() {
    if ($('.page-carte').length > 0) {
        if (!isMobileOrSmalller() && whereIsTheSearchBar != 'inmap') {
            $('.page-carte .map').append($('.algolia-place'));
            whereIsTheSearchBar = 'inmap';
        }
        if (isMobileOrSmalller() && whereIsTheSearchBar != 'infilter') {
            $('.overlay-filtre .content').prepend($('.algolia-place')[0]);
            whereIsTheSearchBar = 'infilter';
        }
    }
}


var carteInteIndexAlgolia = null;
var carteInteMarkersCluster = null;
var carteInteMarkersList = {};

function callbackMapInte(macarte) {
    macarte.zoomControl.setPosition('bottomleft');
    if (typeof carteInteMarkersList == 'undefined') {
        setTimeout(function () {
            callbackMapInte(macarte);
        }, 250);
        return false;
    }
    carteInteCreateAllMarkerIcon();
    carteInteDrawAllMarker(macarte);
    carteInteDrawAllTrace();
}

function carteInteCreateAllMarkerIcon() {
    $.each(dataMapInteractiveMarkers, function (cat, icons) {
        th_maps.addMarkerIcon('map_inte_red_' + cat, {
            iconUrl: icons.red,
            shadowUrl: null,
            iconSize: [32, 37]
        });

        th_maps.addMarkerIcon('map_inte_black_' + cat, {
            iconUrl: icons.black,
            shadowUrl: null,
            iconSize: [32, 37]
        });
    });
}


function carteInteDrawAllMarker(macarte) {
    carteInteMarkersCluster = L.markerClusterGroup({showCoverageOnHover: false});

    macarte.addLayer(carteInteMarkersCluster);

    $.each(dataMapInteractiveFiches, function (cat, fiches) {
        carteInteMarkersList[cat] = [];
        $.each(fiches, function (k, fiche) {
            carteInteMarkersList[cat].push(carteInteGenerateMarker(macarte, cat, fiche));
        });
    });

}

const grandTraceItineraire = [];

function carteInteDrawAllTrace() {
    let i = 0;
    dataMapInteractiveTraces.forEach((trace) => {
        if (typeof trace.trace.url !== 'undefined') {
            grandTraceItineraire['grand-trace-' + i] = omnivore.kml(trace.trace.url).on("ready", function (e) {
                const options = {
                    weight: 3,
                    color: trace.couleur,
                    fillOpacity: 0.0,
                    opacity: 1,
                }
                this.setStyle(options);
            });
        }
        i++;
    });
}


function carteInteGenerateMarker(macarte, cat, fiche) {
    let markerIsActive = false;
    let markerInfowindow = null;

    let trace = null;
    const marker = th_maps.createMarker(null, {
        lat: fiche.lat,
        lng: fiche.lng
    }, 'map_inte_black_' + cat, 'cat-' + cat);

    const loadTrace = (response) => {
        if (response && response.criteres && response.criteres[900002] && response.criteres[900002].exist && response.criteres[900002].valeur) {
            return omnivore.kml(response.criteres[900002].valeur).on("ready", function (e) {
                var highlightStyle = {
                    fillColor: macarte.getContainer().getAttribute("data-kml-color") ?? "#000",
                    weight: macarte.getContainer().getAttribute("data-kml-weight") ?? 3,
                    opacity: 1,
                    color: macarte.getContainer().getAttribute("data-kml-color") ?? "#000",
                    fillOpacity: 0.0
                };

                this.setStyle(highlightStyle);
            }).addTo(macarte);
        }

        return null;
    }

    const handleMarkerLeave = (marker, trace) => {
        if (trace) {
            macarte.removeLayer(trace);
        }
    }

    if (isTouchDevice() || isMobileOrSmalller()) {
        marker.addEventListener('click', function () {
            if (!markerIsActive) {
                markerIsActive = true;

                if (!carteInteIndexAlgolia) {
                    carteInteIndexAlgolia = thuriaSearch.client.initIndex('lei_' + $('html').data('lang'));
                }

                carteInteIndexAlgolia.getObject(fiche['id'], function (err, response) {
                    var htmlCard = cardGenerator.generate(response);

                    $('.data-bottom').html('<div class="cards-mini"><div><span class="close"><span class="icon-ico-gallery-cross"></span></span>' + htmlCard + '</div></div>');
                    $('.data-bottom .cards-mini > div .close').on('click', function () {
                        $('.data-bottom .cards-mini').animate({opacity: 0}, 300, function () {
                            $(this).remove();
                        });
                    });

                    if (!trace) {
                        trace = loadTrace(response);
                    } else {
                        trace.addTo(macarte);
                    }
                });
            } else {
                markerIsActive = false;

                handleMarkerLeave(marker, trace);
            }
        });
    } else {
        marker.addEventListener('mouseover', function () {
            if (!markerIsActive) {
                markerIsActive = true;

                if (!carteInteIndexAlgolia) {
                    carteInteIndexAlgolia = thuriaSearch.client.initIndex('lei_' + $('html').data('lang'));
                }

                if (!markerInfowindow) {
                    carteInteIndexAlgolia.getObject(fiche['id'], function (err, response) {
                        var htmlCard = cardGenerator.generate(response);
                        let width = 250;
                        if ($('body').hasClass('site_velo') && cardGenerator.getObjectType(response) === 'itineraire') {
                            width = 460;
                        }
                        markerInfowindow = th_maps.createInfoWindow(htmlCard, marker, width, [0, -20]);

                        marker.openPopup();

                        if (!trace) {
                            trace = loadTrace(response);
                        } else {
                            trace.addTo(macarte);
                        }
                    });
                } else {
                    if (!marker.isPopupOpen()) {
                        marker.openPopup();
                    }

                    trace.addTo(macarte);
                }
            }
        });

        marker.addEventListener("mouseout", function (e) {
            if (markerIsActive) {
                markerIsActive = false;

                handleMarkerLeave(marker, trace, e);
            }
        });
    }

    return marker;
}

function carteInteRefresh(k) {
    const macarte = th_maps.getMap($('.page-carte .map .maps').get(0));

    if ($('body').hasClass('site_velo')) {
        const tileLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
        const printOverlay = document.querySelector('.overlay-print')
        const printPlugin = L.easyPrint({
            title: 'Imprimer',
            position: 'bottomright',
            sizeModes: ['A4Portrait', 'A4Landscape'],
            exportOnly: true,
            hidden: true,
            tileLayer: tileLayer
        }).addTo(macarte);

        document.querySelector('[data-print-overlay-open]').addEventListener('click', () => {
            printOverlay.dataset.printOverlayShow = 'true'
        })

        document.querySelector('[data-print-overlay-close]').addEventListener('click', () => {
            printOverlay.dataset.printOverlayShow = 'false'
        })

        document.querySelector('[data-print-map]').addEventListener('click', () => {
            const orientationName = {
                'vertical': 'A4Portrait',
                'horizontal': 'A4Landscape'
            }
            printPlugin.printMap(orientationName[printOverlay.dataset.printOverlayOrientation], 'alsace-a-velo');
        })

        document.querySelector('[data-rotate-print-map]').addEventListener('click', () => {
            const printOverlaySizes = {
                horizontal: {
                    width: '1045',
                    height: '715'
                },
                vertical: {
                    width: '715',
                    height: '1045'
                }
            }

            printOverlay.dataset.printOverlayOrientation = printOverlay.dataset.printOverlayOrientation === 'horizontal' ? 'vertical' : 'horizontal'

            const width = Math.min(window.innerWidth, printOverlaySizes[printOverlay.dataset.printOverlayOrientation].width)
            const height = Math.min(window.innerHeight, printOverlaySizes[printOverlay.dataset.printOverlayOrientation].height)

            printOverlay.style.setProperty('--overlay-print-horizontal-size', width + 'px')
            printOverlay.style.setProperty('--overlay-print-vertical-size', height + 'px')
        })
    }

    if (carteInteMarkersCluster) {
        carteInteMarkersCluster.clearLayers();
    }

    var cpt = 0;
    $('form.content input[type="checkbox"]', '#overlay-cartemenu').each(function () {
        var cat = $(this).data('id');
        var id = $(this).attr('id');

        if ($(this).hasClass('grandTrace')) {
            if (this.checked) {
                console.log('================= check');
                grandTraceItineraire[id].addTo(macarte);
            } else {
                console.log('================= no check');
                macarte.removeLayer(grandTraceItineraire[id]);
            }
        } else {
            if (this.checked) {
                $.each(carteInteMarkersList[cat], function (k, v) {
                    carteInteMarkersCluster.addLayer(v);
                });
                cpt++;
            }
        }


    });

    var htmlCpt = '';
    if (cpt > 0) {
        htmlCpt = '(' + cpt + ')';
    }
    $('.btn-filtre span').html(htmlCpt);
}