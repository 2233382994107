class leiItiManager {
    _selector = {
        global: '.itinerire-lei',
        mapContainer: '.itinerire-lei__map',
        maps: '.maps',
        btnMap: '.open-map',
        btnMapClose: '.close-map',
    }

    maps = null;
    markers = [];
    lastActiveMarker = null;

    constructor() {
        this.initAll();
        thPWA.callbacks.leiItiManager = () => { this.initAll(); };
    }

    initAll() {
        this.listenerMapMobile();
        this.itiSegmentData();
        this.itiElevationGraph();
        this.fichesAutour();
    }

    fichesAutour() {
        if (selectionsAutour) {
            this.fichesAutourToggle();
            this.fichesAutourAddMarkers();
            this.fichesAutourSelect();
        }
    }

    fichesAutourToggle() {
        const container = document.querySelector(".js-itineraire-lei-a-proximite");
        const btn = document.querySelector(".js-itineraire-lei-a-proximite-btn");

        if (container && btn) {
            btn.addEventListener("click", (e) => {
                e.preventDefault();

                if (container.classList.contains("is-active")) {
                    container.classList.remove("is-active");
                } else {
                    container.classList.add("is-active");
                }
            })
        }
    }

    fichesAutourAddMarkers() {
        const _self = this;

        selectionsAutour.forEach(selection => {
            let icon = null;

            if (selection.icon) {
                icon = "icon-" + selection.selection_id;

                th_maps.addMarkerIcon(icon, {
                    iconUrl: selection.icon,
                    shadowUrl: null,
                    iconSize: [25, 25],
                    iconAnchor: [13, 13]
                });
            }

            if (selection.points) {
                selection.points.forEach(point => {
                    const marker = th_maps.createMarker(null, {
                        lat: point.lat,
                        lng: point.lng
                    }, icon ? icon : 'default', 'selection-' + selection.selection_id);
                    const lat = point.lat;
                    const lng = point.lng;
                    const post_id = point.id;

                    this.markers.push(marker);

                    marker.on('click', () => {
                        if (this.lastActiveMarker) {
                            if (icon) {
                                this.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                            } else {
                                this.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                            }
                            this.lastActiveMarker = null;
                        }
                        marker.setIcon(th_maps.markersIcons['default-hover']);
                        this.lastActiveMarker = marker;

                        $.ajax({
                            url: "/wp-admin/admin-ajax.php",
                            method: "POST",
                            data: {
                                action: "get_carte_interactive_point",
                                post_id: post_id
                            },
                            success: function (html) {
                                const response = JSON.parse(html);

                                var infoWindow = th_maps.createInfoWindow(response.html);
                                infoWindow.on('remove', function () {
                                    if (icon) {
                                        _self.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                    } else {
                                        _self.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                    }
                                });
                                infoWindow.setLatLng({lat: lat, lng: lng}).openOn(_self.maps);
                            }
                        });
                    })
                });
            }
        });
    }

    fichesAutourSelect() {
        const inputs = [...document.querySelectorAll(".js-itineraire-lei-a-proximite input[type='checkbox']")];

        if (inputs.length) {
            inputs.forEach(input => {
                input.addEventListener("change", e => {
                    e.preventDefault();

                    if (input.checked) {
                        th_maps.showGroupMarker('selection-' + input.value, this.maps);
                    } else {
                        th_maps.hideGroupMarker('selection-' + input.value);
                    }
                })
            })
        }
    }

    // Responsive manager
    listenerMapMobile() {
        let ceci = this;
        $(ceci._selector.btnMap, ceci._selector.global).on('click', function () {
            ceci.toggleMap();
        });
        $(ceci._selector.btnMapClose, ceci._selector.global).on('click', function () {
            ceci.toggleMap();
        });
    }

    // Segments & map manager
    toggleMap() {
        $(this._selector.mapContainer).toggleClass('open');
    }

    itiSegmentData() {
        this.getMap();
        this.initMapKML();
    }

    initMapSegments() {
        if (this.maps) {
            this.addSegmentsToMap();
        }
    }

    initMapKML() {
        if (this.maps) {
            this.addTraceToMap();
        }
    }

    getMap() {
        const mapContainer = document.querySelector(`${this._selector.mapContainer} ${this._selector.maps}`);

        this.maps = th_maps.initSingleMap(mapContainer);
    }

    addSegmentsToMap() {
        if (thSeg) {
            Object.keys(thSeg).forEach(type => {
                thSeg[type].forEach(singleTrace => {
                    L.geoJSON([{
                        type: "LineString",
                        coordinates: singleTrace
                    }], {
                        style: {
                            color: type,
                            weight: 3,
                            fillOpacity: 0.0,
                            opacity: 1,
                        },
                    }).addTo(this.maps);
                });
            });
        }
    }

    addTraceToMap() {
        const style = {
            weight: 3,
            color: '#D74537',
            fillOpacity: 0.0,
            opacity: 1,
        }

        if (thIti) {
            const boundsIti = L.latLngBounds();

            thIti.forEach(itiLine => {
                const geojson = L.geoJSON([{
                    type: "LineString",
                    coordinates: itiLine
                }], {
                    style: style,
                }).addTo(this.maps);

                boundsIti.extend(geojson.getBounds());
            });

            this.maps.fitBounds(boundsIti);
            this.initMapSegments();
        } else {
            const kml = $(this._selector.mapContainer + ' ' + this._selector.maps).attr('data-iti-kml');

            if (kml) {
                const cela = this;
                const kmlItineraire = omnivore.kml(kml).on("ready", function (e) {
                    this.setStyle(style);
                    cela.maps.fitBounds(e.target.getBounds());

                    cela.initMapSegments();
                }).addTo(this.maps);
            }
        }
    }

    // Profil altimétrique manager
    itiElevationGraph() {
        const chartColors = "#D74537";
        const bgChartCurve = "#D74537";

        if ($('#denivele').length > 0) {
            //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
            const YAxis = $('#denivele').attr('data-YAxis').split(',');
            // YAxis.push(100);
            const XAxis = $('#denivele').attr('data-XAxis').split(',');


            const ctx = $('#denivele');
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: XAxis,
                    datasets: [
                        {
                            label: 'DÉNIVELÉ (m)',
                            data: YAxis,
                            borderColor: chartColors,
                            fill: true,
                            backgroundColor: bgChartCurve,
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            pointHitRadius: 10,
                            lineTension: 0.1,
                        }
                    ]
                },

                hover: {
                    mode: 'index'
                },
                options: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'DISTANCE (km)',
                                fontStyle: "none",
                                fontFamily: 'Arial'
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                stepSize: 50
                            },
                            gridLines: {
                                drawOnChartArea: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'ALTITUDE (m)',
                                fontFamily: 'Arial',
                                fontStyle: "none",
                                padding: {
                                    bottom: 20
                                }
                            }
                        }],
                    },
                    tooltips: {
                        bodyFontFamily: 'Arial',
                        titleFontFamily: 'Arial',
                        displayColors: false,
                    }
                },
            });

            document.getElementById("denivele").onmousemove = function (evt) {
                var activePoint = myChart.getElementAtEvent(event);

                // make sure click was on an actual point
                if (activePoint.length > 0) {
                    $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
                    //$('#overlay-itineraire-etape').addClass('open');
                    // $('.shadow-overlay').addClass('open');
                } else {
                    $('[data-markerindex]').removeClass('active');
                }
            };
        }
    }

}

if ($('.itinerire-lei:not(.--not-lei)').length > 0) {
    const LeiIt = new leiItiManager;
}