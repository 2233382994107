var updateOuvertureLEIID = function(){

    var ids = [];
    $('[data-ouverturele-id]').each(function(){
        ids.push($(this).attr('data-ouverturele-id'));
    });

    $.get(document.location.origin+'/wp-content/plugins/LEI/api/ouverture.php',{ids:ids,date:(new Date()).getDate()},function(response){
        $.each(response,function(key,val){
           $('[data-ouverturele-id="'+key+'"]').each(function(){
              if(val == 0){
                  $(this).html('');
              }else{
                  $(this).html($(this).attr('data-ouverturele-text'));
              }
           });
        });
    },'json');
};

updateOuvertureLEIID();
