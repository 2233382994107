thBlocs.add('dataAnchorClick',function(){
  $('[data-anchor]').each(function(){
    $(this).on('click',function(e){
      e.preventDefault();
      e.stopPropagation();
      var anchor = $(this).attr('data-anchor');
      if(anchor == 'scroll')
      {
        $('html, body').animate({scrollTop:($('html,body').scrollTop()+document.body.clientHeight-65)},'slow');
      }else{
        $('html, body').animate({scrollTop:$($(this).attr('href')).offset().top},'slow');
      }
      return false;
    });
  });
});
