th_maps.addThemes('default', thConfig.map.defaultThemes);

th_maps.onLoad(function () {
    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/ico-marker-map-dark-2x.png',
        shadowUrl: null,
        iconSize: [35, 43]
    });

    th_maps.addMarkerIcon('default-hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/ico-marker-map-red-2x.png',
        shadowUrl: null,
        iconSize: [35, 43]
    });

    /* Scrolly */
    /* ================================================ */
    /* ================================================ */
    // les différents thèmes
    var sites = ['site_main','site_noel','site_vins','site_velo'];
    // var sites = ['site_velo'];
    sites.forEach((siteKey) => {
        for (let i = 1; i < 10; i++){
            th_maps.addMarkerIcon('scrolly-'+siteKey+'-'+i, {
                iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/scrolly/'+siteKey+'/scrolly-'+siteKey+'-'+i+'.png',
                shadowUrl: null,
                iconSize: [38, 38]
            });
        }

        th_maps.addMarkerIcon('scrolly-'+siteKey+'-default', {
            iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/scrolly/'+siteKey+'/scrolly-'+siteKey+'-default.png',
            shadowUrl: null,
            iconSize: [38, 38]
        });

        th_maps.addMarkerIcon('scrolly-'+siteKey+'-passed', {
            iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/scrolly/'+siteKey+'/scrolly-'+siteKey+'-passed.png',
            shadowUrl: null,
            iconSize: [20, 20]
        });

        th_maps.addMarkerIcon('scrolly-'+siteKey+'-current', {
            iconUrl: '' + document.location.origin + '/wp-content/themes/alsace/assets/images/ico/scrolly/'+siteKey+'/scrolly-'+siteKey+'-current.png',
            shadowUrl: null,
            iconSize: [60, 60]
        });
    });


});

th_maps.init(thConfig.map.init);
