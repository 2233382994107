var popinPub = {
    eventTriggered: false,
    idmodal: 'overlay-popin',
    nb_pages: 0,

    hasLocalStorage: false,
    hasgaq: false,

    autoDisplay: false,

    init: function () {
        popinPub.testLocalStorage();
        popinPub.testGaq();

        if(typeof popin_settings === 'undefined'){
            return null;
        }
        if (popin_settings.nb_pages > 0) {
            popinPub.nb_pages = popin_settings.nb_pages;
        }

        if (!popinPub.hasLocalStorage) {
            return false;
        }

        $('.form-sent').hide();

        var viewCount = popinPub.incrementPageCount();

        if (popinPub.checkAlreadySee() && !$('#'+popinPub.idmodal).hasClass('preopen')) {
            return false;
        }


        if (popin_settings.enabled) {
            if (viewCount >= popinPub.nb_pages) {
                if (popin_settings.display_mouse_leave) {
                    popinPub.listenEventCloseTab();
                } else {
                    popinPub.open();
                }
            }
        }

        if($('#'+popinPub.idmodal).hasClass('preopen')){
            popinPub.open();
        }
    },

    testGaq: function () {
        if (typeof window._gaq != 'undefined') {
            popinPub.hasgaq = true;
        }
    },

    testLocalStorage: function () {
        try {
            localStorage.setItem('hasLocalStorage', 'oui');
            localStorage.removeItem('hasLocalStorage');
            popinPub.hasLocalStorage = true;
        } catch (e) {
            popinPub.hasLocalStorage = false;
        }
    },

    incrementPageCount: function () {
        var count = localStorage.getItem('popinpub-pagevue');
        if (count == null) {
            count = 1;
        } else {
            count = parseInt(count) + 1;
        }
        localStorage.setItem('popinpub-pagevue', count);

        return count;
    },

    checkAlreadySee: function () {
        var alreadySee = localStorage.getItem('popinpub-already');
        if (alreadySee === null) {
            return false;
        }
        return true;
    },

    listenEventCloseTab: function () {
        $("body").on("mouseleave", function (e) {
            if (e.offsetY - $(window).scrollTop() < 0 && !popinPub.eventTriggered) {
                popinPub.eventTriggered = true;
                $("body").off('mouseleave');
                popinPub.open();
            }
        });
    },

    open: function () {
        $('#' + popinPub.idmodal + ' form').submit(function (e) {
            // e.preventDefault();
            popinPub.confirmSubmit();
        });

        popinPub.openModal();

        setTimeout(function () {
            localStorage.setItem('popinpub-already', '1');
        }, 500)
    },

    openModal: function () {
        setTimeout(function () {
            th_overlayModified.open(popinPub.idmodal);
        }, 100);
    }
};

popinPub.init();