var thLang = {
    current: 'fr',

    strings: {
        fr: {
            maposition: 'Ma position',

            geoloc_error_base: "Erreur lors de la géolocalisation : ",
            geoloc_error_1: "Timeout !",
            geoloc_error_2: "Vous n’avez pas donné la permission",
            geoloc_error_3: "La position n’a pu être déterminée",
            geoloc_error_unknow: "Erreur inconnue",
        },
        en: {

        },
        de: {

        },
        nl: {

        },
        it: {

        },
        es: {

        }
    },



    init: function(){

        thLang.current = document.getElementsByTagName('html')[0].getAttribute('data-lang');
    },

    get: function(key){
        if(typeof thLang.strings[thLang.current][key] === 'undefined'){
            return key;
        }
        return thLang.strings[thLang.current][key];
    }

};

thLang.init();