function initAllSliders() {
    var sliders = document.querySelectorAll('.th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }


        // Note : les variables textNav et totalSlidesNumber ont été crées juste pour ce projet vu qu'on a un slider de liens
        // (au click sur un lien on doit afficher une slide en particulier)
        // On passe ces deux variables à la fonction onInitTextNav dans le th_slider.fct pour faire marcher le script


        var totalSlidesNumber = parent.querySelectorAll('.th-slider > *').length;
        var textNav = parent.querySelectorAll('.nav-links button');

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {


                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

                if (textNav) {
                    thSliderTools.onInitTextNav(slider, textNav, totalSlidesNumber);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
                if (textNav.length > 0) {
                    thSliderTools.onChangeTextNav(slider, textNav, totalSlidesNumber);
                }

            }
        });

    }
}


function isSliderReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderReady, 1000);
        return false;
    }
    initAllSliders();
}

thBlocs.add('th-sliders', function () {
    isSliderReady();
});
