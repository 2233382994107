thWeather.config({
    apiUrl: template_path + 'inc/ajax/openweathermap.php',
    soir: '21:00:00',
    nuit: '03:00:00',
});

moment.locale(thGlobalDefaults.currentlanguage);

function initMeteo() {
    var momentList = ['aprem'];
    var day = [0,1,2];
    var keep_only_current_moment = false;

// gestion de celui qui est visible directement dans le menu principal
    var topweather = false;

    $('[data-weather]').each(function(){

        var _self = this;

        var city = $(this).data('weather');

        var spe_day = $(this).data('day');
        if(typeof spe_day != 'undefined') {
            day = [spe_day];
        }

        $.each(day,function(k,day){
            var maxTemp = 0;
            var minTemp = 1000;
            var removeChecker = [];
            var already_res_for_day = false;

            $.each(momentList,function(k,periode){
                //Données aux différents moments de la journée

                thWeather.get({city:city,moment:periode,day:day,lang:thGlobalDefaults.currentlanguage},function(data){
                    if(
                        typeof data !== "undefined"
                        && data !== null
                        && data
                        && (!keep_only_current_moment || (keep_only_current_moment && !already_res_for_day))
                    ){
                        var date = moment(data.dt*1000).format('ddd D MMM');

                        maxTemp = (data.temp_max > maxTemp) ? data.temp_max : maxTemp;
                        minTemp = (data.temp_min < minTemp) ? data.temp_min : minTemp;

                        var windspeed = Math.round((data.wind.speed * 3.6));
                        var windDirection = data.winddirection.deg;

                        $('.temp', _self).html(data.degrees + "°C");
                        $('.data-icon', _self).html(data.iconHtml);
                        $('.wind .direction', _self).text(data.winddirection.direction);
                        $('.wind .vitesse', _self).html(windspeed);


                        already_res_for_day = true;
                    }else{
                        $('#day-'+day+' .'+periode,_self).html('');
                        removeChecker.push(true);
                    }
                });

                if (removeChecker.length >= 4) {
                    $("#day-" + day, _self).remove();
                }
            });
        });
    });
}

initMeteo();