thPWA.callbacks.overlay = function(){

    th_overlayModified.parse( $('#pwa-content').get(0) );

};

// Scripts pour ajouter ou supprimer la classe "no-scroll"
function addNoScroll() {
    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
}

function removeNoScroll() {
    if (document.body.classList.contains("no-scroll") && !$(th_overlayModified.selector_overlay_fiche_sit).hasClass('open')) {
        document.body.classList.remove("no-scroll");
    }
}

th_overlayModified.addCallbackOpen(addNoScroll);
th_overlayModified.addCallbackClose(removeNoScroll);