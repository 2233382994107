// detection du scroll pour jouer avec l'affichage de la bar du haut
var lastscrolltop = 0;
var lastIsDirTop = 0;
var elScrollForMenu = document;
$(elScrollForMenu).on('scroll', handleScrollForMenu);
function handleScrollForMenu() {
    var st = $(elScrollForMenu).scrollTop();
    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        $(document.body).addClass('scrolldir-top', true);
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        $(document.body).removeClass('scrolldir-top', true);
    }
    lastscrolltop = st;

    if (st > 100) {
        $("body").addClass("is-scrolled");
        $('.social-share').addClass('fadein');
    }
    else {
        $("body").removeClass("is-scrolled");
        $('.social-share').removeClass('fadein');
    }
}


// Initialisation de la recherche
// Fake non pour pas d'autocomplete
$('.bloc-form-algolia input').attr('name', (new Date()).getTime() + '-search');
var indexAlgoliaString = 'lei_'+$('html').data('lang');
var algoliaCurrentLanguage = 'Français';
if(indexAlgoliaString == 'lei_en'){
  algoliaCurrentLanguage = 'English';
}else if(indexAlgoliaString == 'lei_es'){
  algoliaCurrentLanguage = 'Español';
}else if(indexAlgoliaString == 'lei_de'){
  algoliaCurrentLanguage = 'Deutsch';
}else if(indexAlgoliaString == 'lei_nl'){
  algoliaCurrentLanguage = 'Nederlands';
}else if(indexAlgoliaString == 'lei_it'){
  algoliaCurrentLanguage = 'Italiano';
}

setTimeout(function () {
    // init de algolia
    thuriaSearch.init(thConfig.algolia.id, thConfig.algolia.key, ['wp_posts_experiences', 'wp_posts_page', 'wp_posts_itineraires', 'wp_posts_recettes', 'wp_posts_blog', indexAlgoliaString], document.getElementById('search'), $('.wrapper', '#fast-search-algolia').get(0), $('#fast-search-algolia').get(0));
}, 1000);


/* EN DESKTOP OUVERTURE DU MENU */
$('.navTrigger-desktop').on('click', function () {
    if ($('input#menu-toggle').is(':checked')) {
        $('body').removeClass('no-scroll');
        $('.navTrigger-desktop').removeClass('active');
    } else {
        $('body').addClass('no-scroll');
        $('.navTrigger-desktop').addClass('active');
    }
});


/* OUVERTURE DE ALGOLIA SEARCH AU MOMENT DU FOCUS SUR LE INPUT DU MOBILE ET CELUI DU DESKTOP */
// ouverture
$('#search-mobile, #search').on('focus', function () {
    $('nav#nav').addClass('algolia-search');
});
// fermeture
$('#fast-search-algolia .container > label').on('click', function () {
    $('nav#nav').removeClass('algolia-search');
});

// ouverture
$('#search').on('focus', function () {
    $('#menu-toggle').prop("checked", true);
    $('.navTrigger-desktop').addClass('active');
    if ($('input#menu-toggle').is(':checked')) {
        $('body').addClass('no-scroll');
    } else {
        $('body').removeClass('no-scroll');
    }
});

// deplacement du formulaire de recherche suivant si c'est mobile ou desktop
var whereIsSearchFormAlgolia = 'header';
if (!isMobileOrSmalller()) {
    whereIsSearchFormAlgolia = 'header';
    $('#header').append($('#header .bloc-form-algolia'));
}
else {
    whereIsSearchFormAlgolia = "menu";
    $('#header .form-search-mobile').append($('#header .bloc-form-algolia'));
}


$(window).on('resize', function () {
    if (!isMobileOrSmalller()) {
        if (whereIsSearchFormAlgolia == 'menu') {
            whereIsSearchFormAlgolia = 'header';
            $('#header').append($('#header .bloc-form-algolia'));
        }
    }

    else {
        if (whereIsSearchFormAlgolia == 'header') {
            whereIsSearchFormAlgolia = 'menu';
            $('#header .form-search-mobile').append($('#header .bloc-form-algolia'));
        }
    }
});


function resetMenuFormItems() {
    $('input#search').val('');
    $('#menu-back-0').click();
    $('#menu-back-0').get(0).checked = false;
    $('nav#nav').removeClass('algolia-search');
    $('body').removeClass('no-scroll');
}

$('#menu-toggle').on('change', function () {

    setTimeout(function () {
        if (document.getElementById('menu-toggle').checked == false) {
            resetMenuFormItems();
        }
    }, 500);

});
