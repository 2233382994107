var cardGenerator = {
    currentLocale: null,
    isRouteVins: false,
    typeIds: {},

    months: {
        'fr-FR': ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
        'en-GB': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        'de-DE': ['Jän.', 'Feb.', 'März', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.'],
        'nl-NL': ['Jän.', 'Feb.', 'März', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.'],
        'es-ES': ['enero', 'feb.', 'marzo', 'abr.', 'mayo', 'jun.', 'jul.', 'agosto', 'sept.', 'oct.', 'nov.', 'dic.'],
        'it-IT': ['genn.', 'febbr.', 'mar.', 'apr.', 'magg.', 'giugno', 'luglio', 'ag.', 'sett.', 'ott.', 'nov.', 'dic.']
    },

    itiClass: {1: 'duree', 2: 'transport', 3: 'distance'},
    prices: [
        [1900533, 1900533, '<span>/ chambres double</span>'],
        [1900533, 1904994, '<span>/ 2 personnes avec petit déjeuner</span>'],
        [1900535, 1904062, '<span>/ semaine</span>'],
        [1900955, 1907624, '<span>/ nuit</span>'],
        [1900793, 1900204, '<span></span>'],
    ],

    generate: function (data) {
        if (cardGenerator.currentLocale == null) {
            cardGenerator.currentLocale = $('html').attr('lang');
        }

        var lg = translations;
        if (!lg) {
            lg = translations;
        }

        cardGenerator.prices[0][2] = '/ ' + lg.chambreDouble;
        cardGenerator.prices[1][2] = '/ ' + lg.perso2dej;
        cardGenerator.prices[2][2] = '/ ' + lg.semaine;
        cardGenerator.prices[3][2] = '/ ' + lg.nuit;

        if (data.criteres) {
            return cardGenerator.LEI(data);
        } else {
            return cardGenerator.POST(data);
        }
    },

    LEI: function (data) {
        if (cardGenerator.getObjectType(data) == 'manif') {
            return cardGenerator.LEI_Event(data);
        } else if(cardGenerator.getObjectType(data) == 'itineraire') {
            return cardGenerator.LEI_Iti(data);
        } else {
            return cardGenerator.LEI_Not_Event(data);
        }
    },

    LEI_Event: function (data) {

        var lg = translations;

        var displayDuAu = false;

        if (data.horaires && data.horaires.length > 0) {
            displayDuAu = [];
            var splitedDateDebut = data.horaires[0].date_debut.split('/');
            var splitedDateFin = data.horaires[0].date_fin.split('/');
            var dateDebut = new Date(splitedDateDebut[2] + '-' + splitedDateDebut[1] + '-' + splitedDateDebut[0]);
            var dateFin = new Date(splitedDateFin[2] + '-' + splitedDateFin[1] + '-' + splitedDateFin[0]);

            if (dateDebut.getTime() == dateFin.getTime()) {
                displayDuAu.push('<time><span>' + lg.le + ' ' + dateDebut.getDate() + '</span>');
                displayDuAu.push('<span class="month">' + cardGenerator.months[cardGenerator.currentLocale][dateDebut.getMonth()] + '</span>');
                displayDuAu.push('<span class="year">' + dateDebut.getFullYear() + '</span></time>');
            } else {
                displayDuAu.push('<time><span>' + lg.du + ' ' + dateDebut.getDate() + '</span>');
                if (dateDebut.getMonth() != dateFin.getMonth() || (dateDebut.getFullYear() != dateFin.getFullYear())) {
                    displayDuAu.push('<span class="month">' + cardGenerator.months[cardGenerator.currentLocale][dateDebut.getMonth()] + '</span>');
                }
                if (dateDebut.getFullYear() != dateFin.getFullYear()) {
                    displayDuAu.push('<span class="year">' + dateDebut.getFullYear() + '</span>');
                }
                displayDuAu.push('</time><time><span>' + lg.au + ' ' + dateFin.getDate() + '</span>');
                displayDuAu.push('<span class="month">' + cardGenerator.months[cardGenerator.currentLocale][dateFin.getMonth()] + '</span>');
                displayDuAu.push('<span class="year">' + dateFin.getFullYear() + '</span></time>');
            }
        }


        var objectType = cardGenerator.getObjectType(data);
        var html = '';
        if (data.geo && data.geo.lat && data.geo.lng) {
            html = '<a href="' + data.permalink + '" class="card bloc-card-event" data-lat="'+ data.geo.lat +'" data-lng="'+ data.geo.lng +'" data-vignettelei="'+ data.objectID +'">';
        } else {
            html = '<a href="' + data.permalink + '" class="card bloc-card-event" data-vignettelei="'+ data.objectID +'">';
        }

        if (cardGenerator.hasModa(data, 1901329, 1912086)){
            html += '<figure class="vins">' +
                '<img src="'+document.location.origin+'/wp-content/themes/alsace/assets/images/logos/logo-vin-70ans-card.png" width="50" height="129"/>\n' +
                '</figure>';
        }
        if (cardGenerator.hasModa(data, 1901481, 1911337) || cardGenerator.hasModa(data, 1900395, 1911985)){
            html += '<figure class="velo">' +
                '<img src="'+document.location.origin+'/wp-content/themes/alsace/assets/images/labels/logo-accueil-velo.png" width="50" height="67"/>\n' +
                '</figure>';
        }

        /* Photo ------------------------------------------------------------ */
        if (data.photo && data.photo.object && data.photo.object.w420) {
            html += '<figure class="fit-cover">';
            html += '<img src="' + data.photo.object.w420.replace('http://', 'https://') + '" width="380" height="320" alt="' + data.photo.legende + '"/>';
            html += '</figure>';
        } else {
            html += '<div class="no-photo"></div>';
        }

        if (displayDuAu) {
            html += '<span class="date-event">' + displayDuAu.join('');
            if (data.horaires.length > 1) {
                html += '<span class="other-dates">' + lg.autresDates + '</span>';
            }
            html += '</span>';
        }

        html += '<div class="bottom">';

        /* Nom et coordonnées ------------------------------------------------------------ */
        html += '<h3 class="title-card">' + data.nom + '</h3>';

        /* Fairguest ------------------------------------------------------------ */
        if (data.avis_api && data.avis_api.count > 0 && data.avis_api.score) {
            var note = Math.round((data.avis_api.score / 10) * 100) / 100;
            html += '<div><span><strong>' + note + '</strong>/10</span><span class="city">' + cardGenerator.getDistance(data) + data.commune + '</span></div>';
        } else {
            html += '<div><span class="city">' + cardGenerator.getDistance(data) + data.commune + '</span></div>';
        }

        /* END ------------------------------------------------------------ */
        html += '</div>';
        html += '</div>';

        return html;
    },

    LEI_Iti: function (data) {
        var lg = translations;

        var objectType = cardGenerator.getObjectType(data);
        var html = '';

        if (typeof data.criteres[900002].valeur !== 'undefined'){
            html = '<a href="' + data.permalink + '" class="card bloc-card-sit bloc-card-lei-itineraire" data-kml="'+data.criteres[900002].valeur+'" data-lat="'+ data.geo.lat +'" data-lng="'+ data.geo.lng +'" data-vignettelei="'+ data.objectID +'">';
        }else{
            html = '<a href="' + data.permalink + '" class="card bloc-card-sit bloc-card-lei-itineraire" data-lat="'+ data.geo.lat +'" data-lng="'+ data.geo.lng +'" data-vignettelei="'+ data.objectID +'">';
        }


        html += '<div class="entete">';
            if (data.photo && data.photo.object && data.photo.object.w420) {
                html += '<figure class="fit-cover">';
                html += '<img src="' + data.photo.object.w420.replace('http://', 'https://') + '" width="380" height="320" alt="' + data.photo.legende + '"/>';
                html += '</figure>';
            } else {
                html += '<div class="no-photo"></div>';
            }

            html += '<div class="titre">';
                html += '<h3 class="title-card">' + data.nom + '</h3>';
                html += '<span class="location">' + data.commune + '</span>';
            html += '</div>';
        html += '</div>';


        html += '<div class="bot">';
            if(typeof data.criteres[1900916] !== 'undefined' && typeof data.criteres[1900916].valeur !== 'undefined'){
                html += '<div class="iti-data">';
                    html += '<span class="icon-distance"></span>';
                    html += '<div>'+data.criteres[1900916].valeur+' km</div>';
                html += '</div>';
            }

            if(typeof data.criteres[1900564] !== 'undefined' && typeof data.criteres[1900564].modalites !== 'undefined' && Object.keys(data.criteres[1900564].modalites).length > 0){
                Object.entries(data.criteres[1900564].modalites).forEach(entry => {
                    const [key, value] = entry;
                    var difficulte = [];
                    switch (key) {
                        case '1904402':
                            difficulte['name'] = "Tout public";
                            difficulte['class'] = "facile";
                            break;
                        case '1904403':
                            difficulte['name'] = "Intermédiaire";
                            difficulte['class'] = "moyen";
                            break;
                        case '1904405':
                            difficulte['name'] = "Confirmé/sportif";
                            difficulte['class'] = "difficile";
                            break;
                        case '1911940':
                            difficulte['name'] = "Expert";
                            difficulte['class'] = "t-difficile";
                            break;
                    }

                    html += '<div class="iti-data">';
                        html += '<span class="difficulte '+difficulte['class']+'"></span>';
                        html += '<div>'+difficulte['name']+'</div>';
                    html += '</div>';
                });
            }

            if(typeof data.criteres[1900917] !== 'undefined' && typeof data.criteres[1900917].valeur !== 'undefined'){
                html += '<div class="iti-data">';
                    html += '<span class="icon-denivele"></span>';
                    html += '<div>'+data.criteres[1900917].valeur+' m</div>';
                html += '</div>';
            }

        html += '</div>';

        html += '</a>';

        return html;
    },

    LEI_Not_Event: function (data) {

        var lg = translations;

        var objectType = cardGenerator.getObjectType(data);
        var html = '';
        var addClass = ' ';
        if (data.geo && data.geo.lat && data.geo.lng) {
            html = '<a href="' + data.permalink + '" class="card bloc-card-sit'+addClass+'" data-lat="'+ data.geo.lat +'" data-lng="'+ data.geo.lng +'" data-vignettelei="'+ data.objectID +'">';
        } else {
            html = '<a href="' + data.permalink + '" class="card bloc-card-sit'+addClass+'" data-vignettelei="'+ data.objectID +'">';
        }


        if (cardGenerator.hasModa(data, 1901329, 1912086)){
            html += '<figure class="vins">' +
                '<img src="'+document.location.origin+'/wp-content/themes/alsace/assets/images/logos/logo-vin-70ans-card.png" width="50" height="129"/>\n' +
                '</figure>';
        }
        if (cardGenerator.hasModa(data, 1901481, 1911337) || cardGenerator.hasModa(data, 1900395, 1911985)){
            html += '<figure class="velo">' +
                '<img src="'+document.location.origin+'/wp-content/themes/alsace/assets/images/labels/logo-accueil-velo.png" width="50" height="67"/>\n' +
                '</figure>';
        }

        /* Prix ------------------------------------------------------------ */
        var displayed = false;
        $.each(cardGenerator.prices, function (k, price) {
            if (!displayed && cardGenerator.hasModa(data, price[0], price[1])) {
                displayed = true;
                html += '<span class="price">' + lg.price + ' <span>' + cardGenerator.getModaVal(data, price[0], price[1]) + '€ ' + price[2] + '</span></span>';
            }
        });

        if (cardGenerator.isRouteVins && cardGenerator.hasModa(data, 1901028, 1908146)) {
            html += '<span class="cave"><span class="icon-ico-vignobles-et-decouvertes"></span></span>';
        }
        /* Coup de coeur ------------------------------------------------------------ */
        if (cardGenerator.hasModa(data, 1900714, 1907036)) {
            html += '<span class="coeur">' + lg.coeur + '</span>';
        }


        /* Photo ------------------------------------------------------------ */
        if (data.photo && data.photo.object && data.photo.object.w420) {
            html += '<figure class="fit-cover">';
            html += '<img src="' + data.photo.object.w420.replace('http://', 'https://') + '" width="380" height="320" alt="' + data.photo.legende + '"/>';
            html += '</figure>';
        } else {
            html += '<div class="no-photo"></div>';
        }

        html += '<div class="caption-txt">';

        /* Notation étoile ------------------------------------------------------------ */
        var notationEtoile = null;
        if (objectType.match(/^(hotel|camping|hotes|gites|collectif|campingcar)$/i)) {

            listNoteEtoile = [1902430, 1902431, 1902432, 1902433, 1902434];
            $.each(listNoteEtoile, function (k, val) {
                if (cardGenerator.hasModa(data, 1900383, val)) {
                    notationEtoile = k + 1;
                }
            });
        }
        if (notationEtoile != null) {
            html += '<div class="rate">';
            for (var i = 0; i != notationEtoile; i++) {
                html += '<span class="icon-ico-star"></span>';
            }
            html += '</div>';
        }

        /* Duree du sejour ------------------------------------------------------------ */
        if (objectType.match(/^(sejour)$/i)) {
            var nom = cardGenerator.getFirstModaNom(data, 1900487);
            if (nom) {
                html += '<span class="duree">' + nom + '</span>';
            }
        }

        /* Maitre restaurateur ------------------------------------------------------------ */
        if (objectType.match(/^(resto)$/i)) {
            if (cardGenerator.hasModa(data, 1900763, 1908137)) {
                html += '<span class="maitre">' + lg.maitre + '</span>';
            }
        }


        /* difficulté rando ------------------------------------------------------------ */
        if (objectType.match(/^(rando)$/i)) {
            var nom = cardGenerator.getFirstModaNom(data, 1900564);
            var mod_id = cardGenerator.getFirstModaId(data, 1900564);
            if (nom) {
                html += '<span class="difficulte d' + mod_id + '">' + nom + '</span>';
            }
        }


        /* Nom et coordonnées ------------------------------------------------------------ */
        html += '<h3 class="title-card">' + data.nom + '</h3>';
        html += '<span class="location">' + cardGenerator.getDistance(data) + data.commune + '</span>';

        /* Ouvert aujourd'hui ------------------------------------------------------------ */
        if (!objectType.match(/^(resto|hotel|camping|hotes|gites|collectif|campingcar|sejour|rando)$/i)) {
            html += '<span class="opening" data-ouverturele-id="' + data.objectID + '" data-ouverturele-text="Ouvert aujourd\'hui">'+ lg.ouvert +'</span>';
        }


        /* Fairguest ------------------------------------------------------------ */
        if (data.avis_api && data.avis_api.count > 0 && data.avis_api.score) {
            var note = Math.round((data.avis_api.score / 10) * 100) / 100;
            html += '<div class="faitguest"><span class="note">' + note + '<span>/10</span></span><p>' + lg.note + '</p><span class="eval">' + data.avis_api.count + ' ' + lg.eval + '</span></div>';
        }


        /* END ------------------------------------------------------------ */
        html += '</div>';
        html += '</div>';
        html += '</a>';

        return html;
    },

    getObjectType: function (data) {
        var selectedType = "basic";
        $.each(cardGenerator.typeIds, function (type, ids) {
            if ($.inArray(data.type.id + "", ids) != -1) {
                selectedType = type;
                return type;
            }
        });
        return selectedType;
    },

    hasModa: function (data, critId, modaId) {
        if (
            data.criteres
            && data.criteres[critId]
            && data.criteres[critId].modalites
            && data.criteres[critId].modalites[modaId]
        ) {
            return true;
        }
        return false;
    },

    getModaVal: function (data, critId, modaId) {
        if (
            data.criteres
            && data.criteres[critId]
            && data.criteres[critId].modalites
            && data.criteres[critId].modalites[modaId]
        ) {
            return data.criteres[critId].modalites[modaId].valeur;
        }
        return null;
    },

    getFirstModaNom: function (data, critId) {
        if (
            data.criteres
            && data.criteres[critId]
            && data.criteres[critId].modalites
        ) {
            var nom = null;
            $.each(data.criteres[critId].modalites, function (modaId, modaData) {
                if (nom == null) {
                    nom = modaData.nom;
                }
            });
            return nom;
        }
        return null;
    },

    getFirstModaId: function (data, critId) {
        if (
            data.criteres
            && data.criteres[critId]
            && data.criteres[critId].modalites
        ) {
            var nom = null;
            $.each(data.criteres[critId].modalites, function (modaId, modaData) {
                nom = modaId;
            });
            return nom;
        }
        return null;
    },

    getFirstModaVal: function (data, critId) {
        if (
            data.criteres
            && data.criteres[critId]
            && data.criteres[critId].modalites
        ) {
            var val = null;
            $.each(data.criteres[critId].modalites, function (modaId, modaData) {
                if (val == null) {
                    val = modaData.value;
                }
            });
            return val;
        }
        return null;
    },

    getDistance: function (data) {
        if (data._rankingInfo && data._rankingInfo.geoDistance) {
            var distance = data._rankingInfo && data._rankingInfo.geoDistance;
            var unit = 'm';
            if (distance > 1000) {
                distance = distance / 1000;
                unit = 'km';
            }

            return (Math.round(distance * 10) / 10) + unit + ' - ';
        }
        return '';
    },

    POST: function (data) {

        if (data.post_type == 'page' && data.cards_data.page_type == 'page-accueil.php') {
            return '';
        }

        var html = '';

        if (data.post_type == 'experiences' || data.post_type == 'blog') {
            html += '<a href="' + data.permalink + '" class="card bloc-card-experiences">';
        } else if (data.post_type == 'itineraires') {
            html += '<a href="' + data.permalink + '" class="card bloc-card-itineraire">';
        } else if (data.post_type == 'recettes') {
            html += '<a href="' + data.permalink + '" class="card bloc-card-recette">';
        } else if (data.post_type == 'page' && data.cards_data.page_type == 'page-activite.php') {
            html += '<a href="' + data.permalink + '" class="card bloc-card-activite">';
        } else if (data.post_type == 'page' && data.cards_data.page_type == 'page-evenement.php') {
            html += '<a href="' + data.permalink + '" class="card bloc-card-top">';
        } else {
            html += '<a href="' + data.permalink + '" class="card bloc-card-standard">';
        }

        /* Photo ------------------------------------------------------------ */
        if (data.cards_data.cover_img) {
            html += '<figure class="fit-cover">';
            html += '<img src="' + data.cards_data.cover_img.url.replace('http://', 'https://') + '" width="380" height="320" alt="' + data.cards_data.cover_img.credit + '"/>';
            html += '</figure>';
        } else {
            html += '<div class="no-photo"></div>';
        }

        if (data.post_type != 'recettes') {
            html += '<div class="caption-txt">';
        }

        if (data.post_type == 'recettes') {
            html += '<div class="center">';
        }

        // surtitre
        if (data.post_type != 'experiences' && data.post_type != 'itineraires' || data.post_type != 'blog') {
            if (data.cards_data.cover_data && data.cards_data.cover_data.surtitre && data.cards_data.cover_data.surtitre != "") {
                html += '<span class="surtitre">' + data.cards_data.cover_data.surtitre + '</span>';
            }
        }

        // categorie
        if (data.post_type == 'experiences' && data.taxonomies && data.taxonomies.exp_cate && data.taxonomies.exp_cate.length > 0) {
            html += '<span class="surtitre categorie">' + data.taxonomies.exp_cate[0] + '</span>';
        }

        // categorie
        if (data.post_type == 'blog' && data.taxonomies && data.taxonomies.blog_cate && data.taxonomies.blog_cate.length > 0) {
            html += '<span class="surtitre categorie">' + data.taxonomies.blog_cate[0] + '</span>';
        }

        // titre lien
        if (data.cards_data.cover_data && data.cards_data.cover_data.titre && data.cards_data.cover_data.titre != "") {
            html += '<h3 class="title-card">' + data.cards_data.cover_data.titre.replace("\n", "<br/>") + '</h3>';
        } else {
            html += '<h3  class="title-card">' + data.post_title.replace("$", "<br/>") + '</h3>';
        }

        if (data.post_type == 'recettes') {
            html += '</div>';
        }

        // intro
        if (data.post_type != 'recettes' && data.post_type != 'experiences' && data.post_type != 'itineraires' && data.post_type != 'blog' && (data.post_type != 'page' || data.cards_data.page_type != 'page-evenement.php')) {
            if (data.cards_data.cover_data && data.cards_data.cover_data.text && data.cards_data.cover_data.text != "") {
                html += '<p>' + data.cards_data.cover_data.text + '</p>';
            }
        }

        var html_to_add = '';
        if (data.post_type == 'itineraires' && data.cards_data && data.cards_data.data_iti) {
            for (var i = 1; i < 4; i++) {
                if (data.cards_data.data_iti['col_' + i + '_text'] != '') {
                    html_to_add += ' <div><span class="icon-ico-' + cardGenerator.itiClass[i] + '"></span><span>' + data.cards_data.data_iti['col_' + i + '_text'] + '</span></div>';
                }
            }
        }
        if (html_to_add != '') {
            html += '<div class="infos">' + html_to_add + '</div>';
        }

        if (data.post_type == 'recettes' && data.cards_data.data_recette && (data.cards_data.data_recette.nb != '' || data.cards_data.data_recette.duration != '' || data.cards_data.data_recette.hardness != '')) {
            html += '<div class="infos">';
            if (data.cards_data.data_recette.nb != '') {
                html += '<div>';
                html += '<div><span class="icon-ico-restauration"></span><span>' + data.cards_data.data_recette.nb + '<span>pers.</span></span></div>';
                html += '</div>';
            }
            if (data.cards_data.data_recette.duration != '') {
                html += '<div>';
                html += '<div><span class="icon-ico-duree"></span><span>' + data.cards_data.data_recette.duration + '</span></div>';
                html += '</div>';
            }
            if (data.cards_data.data_recette.hardness != '') {
                html += '<div>';
                html += '<div><span class="icon-ico-difficulte"></span><span>' + data.cards_data.data_recette.hardness + '</span></div>';
                html += '</div>';
            }
            html += '</div>';
        }

        if (data.post_type == 'experiences' || data.post_type == 'itineraires' || data.post_type == 'blog') {
            html += '<div class="bottom">';

            html += '<div class="global-rate note">';
            var moy = Math.round(data.cards_data.note_count / data.cards_data.note_total);

            for (var i = 1; i <= 5; i++) {
                if (i <= moy) {
                    html += '<span class="icon-ico-heart"></span>';
                } else {
                    html += '<span class="icon-ico-heart disabled"></span>';
                }
            }

            html += '</div>';

            if (data.cards_data && data.cards_data.localisation && data.cards_data.localisation != '') {
                html += '<span class="city">' + data.cards_data.localisation + '</span>';
            }
            html += '</div>';
        }
        html += '</div>';
        html += '</a>';

        return html;
    }
};

cardGenerator.typeIds = cardGeneratorTypeIds;

if ($('body').hasClass('site_vins')) {
    cardGenerator.isRouteVins = true;
}