function thSlider(el, settings) {

    this.el = el;
    this.el.posX = 0;


    this.state = {
        items: null,
        current: 0,
        count: null,
        lastDirection: 1, // Si 1 alors on va vers la droite, 0 gauche
    };

    this.options = {
        duration: 400,
        rtl: 0,
        onchange: null,
        beforechange: null, // marche que si pas de slide
        oninit: null,
        scrollPadding: 0,
        scrollModeMaxWidth: 1280,
        scrollModeClass: 'scroll-mode',
        scrollModeParentClass: 'scroll-mode-parent',
    };


    /**
     * Mise en place du slider (variable de base)
     *
     * @param settings
     * @private
     */
    this._setup = function (settings) {

        // on prend en compte les settings
        if (settings.rtl) {
            this.options.rtl = settings.rtl;
        } // direction inverse (gauche à droite)
        if (settings.onchange) {
            this.options.onchange = settings.onchange;
        } // CALLBACK : action au changement de slide
        if (settings.beforechange) {
            this.options.beforechange = settings.beforechange;
        } // CALLBACK : action avant changement
        if (settings.oninit) {
            this.options.oninit = settings.oninit;
        } // CALLBACK : a l'initialisation
        if (settings.duration || settings.duration === 0) {
            this.options.duration = settings.duration;
        } // durée de l'animation
        if (settings.scrollModeMaxWidth) {
            this.options.scrollModeMaxWidth = settings.scrollModeMaxWidth;
        } // Si container de 900, sur 1280, mettre 1024, active le scroll mode a cette resolution max
        if (settings.scrollModeClass) {
            this.options.scrollModeClass = settings.scrollModeClass;
        } // class du scroll mode, ne pas toucher dans 99% des cas
        if (settings.scrollModeClassInParent) {
            this.options.scrollModeClassInParent = settings.scrollModeClassInParent;
        } // Ajouter la class du scroll mode au parent également ?

        if (settings.draggable) {
            if (!this.isMobile()) {
                this._enableDesktopDrag();
            }
        }
        if (settings.scrollListener) {
            if (this.isMobile()) {
                this._enableSrollListener();
            }
        }

        // scroll mode ou pas
        if (this.isMobile()) {
            if (this.options.scrollModeClass) {
                this.el.className += ' ' + this.options.scrollModeClass;
            }
            if (this.options.scrollModeParentClass) {
                this.el.parentNode.className += ' ' + this.options.scrollModeParentClass;
            }
        }

        this.detectScrollPadding();

        // on met à jour l'état
        this._updateState();

        if (this.options.oninit) {
            this.options.oninit(this);
        }

        // si il y a une animation de mise en place, on met un event en place à la fin de l'animation
        if (this.options.duration && this.options.onchange) {
            var _self = this;
            this.el.addEventListener('transitionend', function () {
                _self.options.onchange(_self);
            }, false);
        }
    }

    /**
     * Permet de detecter a l'init si le slider a un scrollpadding afin de le prendre en compte dans les calculs ensuite
     */
    this.detectScrollPadding = function () {
        var scrollPadding = (window.getComputedStyle(this.el, null)).scrollPadding;
        if (scrollPadding) {
            scrollPadding = parseInt(scrollPadding.replace('px', ''));
        }
        if (!scrollPadding) {
            scrollPadding = 0;
        }
        this.options.scrollPadding = scrollPadding;

    }

    this.isMobile = function () {
        if (document.body.clientWidth < 1024) {
            return true;
        }
        if ('ontouchstart' in document.documentElement && document.body.clientWidth <= this.options.scrollModeMaxWidth) {
            return true;
        }
        return false;
    }

    /**
     * Mise à jour de la variable state (count et items)
     * @private
     */
    this._updateState = function () {

        // préviens les node text entre les items
        this.state.items = [];
        var childs = this.el.childNodes;
        for (var i = 0; i != childs.length; i++) {
            if (childs[i].nodeName != '#text') {
                this.state.items.push(childs[i]);
            }
        }

        // compte le nombre réel d'items
        this.state.count = this.state.items.length;
    };


    this.next = function () {
        var number = this.state.current + 1;
        if (number >= this.state.count) {
            return false;
        }
        this.goto(number);
    };
    this.prev = function () {
        var number = this.state.current - 1;
        if (number < 0) {
            return false;
        }
        this.goto(number);
    };
    this.goto = function (number) {
        this._refresh(number);
    };

    /**
     * Test si on peut faire un next (permet de désactiver le bouton next)
     * @returns {boolean}
     */
    this.canNext = function () {
        if (this.el.scrollLeft + this.el.offsetWidth === this.el.scrollWidth) {
            return false;
        }
        if (this.state.current === this.state.count - 1) {
            return false;
        }
        return true;


    };
    /**
     * Test si on peut faire un prev (permet de désactiver le bouton prev)
     * @returns {boolean}
     */
    this.canPrev = function () {
        if (this.state.current === 0) {
            return false;
        }
        return true;

    }

    /**
     * Indique si le dernièrement élément est entièrement dans la vue du slider ou non
     * @returns {boolean}
     */
    this.lastItemIn = function () {
        if (!this.options.rtl) {
            if (this.state.items[this.state.count - 1].getBoundingClientRect().right > this.el.parentNode.getBoundingClientRect().right) {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.state.items[this.state.count - 1].getBoundingClientRect().left < this.el.parentNode.getBoundingClientRect().left) {
                return true;
            } else {
                return false;
            }
        }

    };


    /**
     * Déplace l'élément slider en css (desktop) / scroll (mobile)
     * @param posX
     * @param duration
     * @private
     */
    this._move = function (posX, duration) {
        if (this.isMobile()) {
            try {
                // avec animation si compatible
                this.el.scroll({
                    left: this.el.scrollLeft + (posX * -1),
                    top: 0,
                    behavior: 'smooth'
                })
            } catch (e) {
                // sans animation si non compatible
                this.el.scrollLeft = this.el.scrollLeft + (posX * -1);
            }
            if (this.options.onchange) {
                this.options.onchange(this);
            }
        } else {
            if (duration) {
                this.el.style.transition = 'all ' + duration + 'ms ease';
            } else {
                this.el.style.transition = null;
            }

            this.el.style.transform = 'translate3d(' + posX + 'px,0,0)';
            this.el.posX = posX;
        }

    }

    /**
     * Calcul le nouvelle élément en première position
     * @returns {number}
     * @private
     */
    this._getCurrent = function () {
        var margeErreur = 0;
        if (this.isMobile()) {
            margeErreur = 2; // +/- 2px par rapport au mobile scroll snap
        }
        var containerLeft = this.el.parentNode.getBoundingClientRect().left - margeErreur + this.options.scrollPadding;
        if (this.options.rtl) {
            var containerRight = this.el.parentNode.getBoundingClientRect().right + margeErreur - this.options.scrollPadding;
        }

        this._updateState();

        var currentItemIsSelected = false;
        for (var i = 0; i < this.state.count; i++) {
            if (this.state.lastDirection) {
                if (!this.options.rtl) {
                    if (this.state.items[i].getBoundingClientRect().left > containerLeft) {
                        this.state.current = i;
                        currentItemIsSelected = true;
                        break;
                    }
                } else {
                    if (this.state.items[i].getBoundingClientRect().left < containerRight) {
                        this.state.current = i;
                        currentItemIsSelected = true;
                        break;
                    }
                }
            } else {
                if (!this.options.rtl) {
                    if (this.state.items[i].getBoundingClientRect().right > containerLeft) {
                        this.state.current = i;
                        currentItemIsSelected = true;
                        break;
                    }
                } else {
                    if (this.state.items[i].getBoundingClientRect().right < containerRight) {
                        this.state.current = i;
                        currentItemIsSelected = true;
                        break;
                    }
                }
            }
        }

        // on a du trop scroll alors c'est le dernier
        if (!currentItemIsSelected) {
            this.state.current = this.state.count - 1;
        }

        return this.state.current;
    };

    /**
     * Met à jour l'affichage du slider en calculant la bonne position
     * @param itemIndex
     * @returns {boolean}
     * @private
     */
    this._refresh = function (itemIndex) {

        if (itemIndex === 0 || itemIndex) {
            if (itemIndex > this.state.count) {
                return false;
            }
            this.state.current = itemIndex;
        } else {
            itemIndex = this._getCurrent();
        }

        var item = this.state.items[itemIndex];


        if (this.options.beforechange) {
            this.options.beforechange(this, itemIndex);
        }

        if (!this.options.rtl) {
            this._move(this._calcMove(item), this.options.duration);
        } else {
            this._move(this._calcMoveRTL(item), this.options.duration);
        }


        if (this.options.onchange) {
            if (this.options.duration) {
                if (!this.onChangeWithDurationAlready) {
                    this.onChangeWithDurationAlready = true;
                    var _self = this;
                    this.el.addEventListener('transitionend', function () {
                        _self.options.onchange(_self);
                    }, false);
                }

            } else {
                this.options.onchange(this);
            }
        }
    };
    this._calcMove = function (item) {
        var containerLeft = this.el.parentNode.getBoundingClientRect().left;
        var sliderLeft = this.el.getBoundingClientRect().left + this.options.scrollPadding;
        var itemLeft = item.getBoundingClientRect().left;

        var left = containerLeft - (containerLeft - sliderLeft + itemLeft);

        return left;
    }
    this._calcMoveRTL = function (item) {
        var containerRight = this.el.parentNode.getBoundingClientRect().right;
        var containerLeft = this.el.parentNode.getBoundingClientRect().left;
        var sliderLeft = this.el.getBoundingClientRect().left + this.options.scrollPadding;
        var itemRight = item.getBoundingClientRect().right;

        var itemLeft = item.getBoundingClientRect().left;

        //alert('itemWidth : '+(itemRight-itemLeft));

        var left = (sliderLeft - containerLeft) + (containerRight - itemRight);

        return left;
    }

    /**
     * Active le deplacement a la souris du slider sur desktop
     * @private
     */
    this._enableDesktopDrag = function () {
        var _self = this;

        var startClientX = null;
        var startLeft = null;
        var isStart = false;
        var drapStartTime = null;
        var dragMove = 0;

        var lastTouchClientX = null;


        function resetOnDragStart(clientX) {
            drapStartTime = new Date().getTime();
            dragMove = 0;
            isStart = true;
            startClientX = clientX;
            startLeft = _self.el.posX;
            if (!startLeft) {
                startLeft = 0;
            }
        }

        function updateOnDragMove(clientX) {
            dragMove = clientX - startClientX;
            _self._move(startLeft + dragMove);
        }

        function doMoveOnDragEnd(clientX) {
            if (dragMove > 20) {
                _self.state.lastDirection = 0;
                _self._move(startLeft + (clientX - startClientX));
                _self._refresh();
            }
            if (dragMove < -20) {
                _self.state.lastDirection = 1;
                _self._move(startLeft + (clientX - startClientX));
                _self._refresh();
            }
        }

        _self.el.addEventListener('mousedown', function (e) {
            e.preventDefault();
            resetOnDragStart(e.clientX);
        });
        document.addEventListener('mousemove', function (e) {
            if (isStart && e.clientX != 0) {
                e.preventDefault();
                e.stopPropagation();
                updateOnDragMove(e.clientX);
            }
        });
        document.addEventListener('mouseup', function (e) {
            if (isStart) {
                isStart = false;
                e.preventDefault();
                e.stopPropagation();
                doMoveOnDragEnd(e.clientX);
            }
        });

        _self.el.addEventListener('touchstart', function (e) {
            e.preventDefault();
            lastTouchClientX = e.touches[0].clientX;
            resetOnDragStart(e.touches[0].clientX);
        });
        document.addEventListener('touchmove', function (e) {
            if (isStart && e.touches[0].clientX != 0) {
                lastTouchClientX = e.touches[0].clientX;
                updateOnDragMove(e.touches[0].clientX);
            }
        });
        document.addEventListener('touchend', function (e) {
            if (isStart) {
                isStart = false;
                e.preventDefault();
                e.stopPropagation();
                doMoveOnDragEnd(lastTouchClientX);
            }
        });

        _self.el.addEventListener('click', function (e) {
            if (dragMove > 20 || dragMove < -20) {
                e.preventDefault();
                e.stopPropagation();
            }
        })
    };

    this._enableSrollListener = function () {
        var _self = this;

        var timeoutScroll = null;

        var onScrollEnd = function () {
            _self._refresh();
        };

        _self.el.addEventListener('scroll', function (e) {

            if (timeoutScroll) {
                clearTimeout(timeoutScroll);
            }

            timeoutScroll = setTimeout(onScrollEnd, 250);

        }, {passive: true});

    };

    this._setup(settings);

    if (this.options.onchange) {
        this.options.onchange(this);
    }

    return this;
}
