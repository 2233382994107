var thConfig = {

    tabletPortraitBreakpoint: 980,
    tabletPaysageBreakpoint: 1024,
    desktopBreakpoint: 1280,
    mobileBreakpoint: 600,

    tagmanager: thGlobalDefaults.tagmanager,

    debug: true,

    themeUrl: document.getElementById('mainscript').getAttribute('data-themeurl'),

    map: {

        init: {
            maps_class: '.maps:not(.no-autoload):not(.initialized)',
            tileLayerUrl: 'https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}.png?access-token={accessToken}',
            accessToken: 'Fp6WNRcISJEelP7lr52a3V4sCJdfv9NSfXq6dRYiU1wPuqfDhWeGT9mgQKMnmXrz',
            tileLayerOptions: {
                attribution: '',
                maxZoom: 16,
                accessToken: 'Fp6WNRcISJEelP7lr52a3V4sCJdfv9NSfXq6dRYiU1wPuqfDhWeGT9mgQKMnmXrz'
            }
        },

        defaultThemes: {
            accessToken: 'Fp6WNRcISJEelP7lr52a3V4sCJdfv9NSfXq6dRYiU1wPuqfDhWeGT9mgQKMnmXrz',
            style: 'https://tile.jawg.io/jawg-sunny.json?access-token=Fp6WNRcISJEelP7lr52a3V4sCJdfv9NSfXq6dRYiU1wPuqfDhWeGT9mgQKMnmXrz',
            attribution: '',
        }

    },


    algolia: {
        key: thGlobalDefaults.algolia.key,
        id: thGlobalDefaults.algolia.id
    },

    algoliaPlace: {
        id: 'plD1E38XHKL3',
        key: '754a453d3ab664bff741bea5b2ad3853',
        aroundAlsace: {
            aroundLatLngViaIP: false,
            aroundLatLng: '48.225088,7.443729',
            aroundRadius: 100 * 1000,
            countries: ['fr'],
            useDeviceLocation: false
        }
    },

    ignToken: "essentiels"
};

var frm_js = {
    "ajax_url":document.location.origin+"/wp-admin/admin-ajax.php",
    "images_url":document.location.origin+"/wp-content/plugins/formidable/images",
    "no_results":""
};