var relationMapMobile = {
    timeoutMobileScrollCards: null,
    mobileScrollLastMarkerId: null,
    markers: {},
    map: null,

    handleScrollMobileCards: function(el,callback){
        var sl = $(el).scrollLeft();
        console.log(sl);
        clearTimeout(relationMapMobile.timeoutMobileScrollCards);
        relationMapMobile.timeoutMobileScrollCards = setTimeout(function(){
            var current = relationMapMobile.mobileCheckCurrentCards(el);
            relationMapMobile.mobileChangeCurrent(current);
            if(callback){
                callback(current);
            }
        },50);
    },

    mobileCheckCurrentCards: function(el){
        var curr = null;
        $('> div > div',el).each(function(){
            var o = $(this).offset();

            if(o.left > -100 && o.left < 160){
                curr = this;
            }
        });

        if(curr){
            return $(curr).data('vignettelei');
        }

        return false;
    },

    mobileChangeCurrent: function(markerId){
        if(markerId != relationMapMobile.mobileScrollLastMarkerId){
            if(relationMapMobile.mobileScrollLastMarkerId){
                relationMapMobile.markers[relationMapMobile.mobileScrollLastMarkerId].setIcon(th_maps.markersIcons['default']);
            }

            if(relationMapMobile.markers[markerId]){
                relationMapMobile.mobileScrollLastMarkerId = markerId;

                var zoom = relationMapMobile.map.getZoom();
                if(relationMapMobile.markers[markerId].__parent){
                    if(relationMapMobile.markers[markerId].__parent._zoom){
                        zoom = relationMapMobile.markers[markerId].__parent._zoom+1
                    }
                }
                relationMapMobile.markers[markerId].setIcon(th_maps.markersIcons['default-hover']);
                if(zoom > 16){
                    zoom = 16;
                }
                relationMapMobile.map.flyTo(relationMapMobile.markers[markerId].getLatLng(),zoom);

            }
        }
    }
}