var forms = document.querySelectorAll('.strate-iti form');
forms.forEach(function(form){
    form.addEventListener('submit', function(e){
        e.preventDefault();
        var valueDep = form.querySelector('#iti_from').value;
        var valueArr = form.querySelector('#iti_to').value;
        var url = form.getAttribute('action');
        url = url.replace('$1', valueDep).replace('$2', valueArr);
        window.open(url, '_blank').focus();
    });
});