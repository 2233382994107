var infoLive = {
    container: '.info-live',
    clickExpand: '.info-live p',
    text: '.info-live p span',
    expand: '.info-live p, .info-live',
    button: '.info-live a.btn',
    textHeight: 20, //en px, 20px étant une ligne


    init: function(){
        if(document.querySelector(infoLive.container)){
            infoLive.checkLength();
        }
    },

    checkLength: function(){
        infoLive.textHeight = document.querySelector(infoLive.text).offsetHeight;
        //Si la taille est suffisante pour avoir besoins d'une expansion, comme ça on rajoute le fondu de couleur en CSS
        if(infoLive.textHeight > 20){
            document.querySelector(infoLive.container).classList.add('to-expand');

            //Au click on vérifie si on doit expand ou reset
            document.querySelector(infoLive.clickExpand).addEventListener('click',function(){
                if(document.querySelector(infoLive.container).classList.contains('expanded')){
                    infoLive.resetHeight();
                }else{
                    infoLive.updateHeight();
                }
            });
        }
    },

    //Expand pour voir tout le texte
    updateHeight: function(){
        document.querySelector(infoLive.container).classList.add('expanded');
        document.querySelectorAll(infoLive.expand).forEach(function(e){
            e.style.maxHeight = 40+infoLive.textHeight+'px';

            if(window.screen.width <= 600 && document.querySelector(infoLive.button)){
                var btnHeight = document.querySelector(infoLive.button).offsetHeight;
                e.style.maxHeight = 50+infoLive.textHeight+btnHeight+'px';
            }
        });
    },

    //Reset
    resetHeight: function(){
        document.querySelector(infoLive.container).classList.remove('expanded');
        document.querySelectorAll(infoLive.expand).forEach(function(e){
            e.style.maxHeight = '20px';
        });
    },
};

infoLive.init();