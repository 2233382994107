// OVERLAY SCROLLY
function callbackMapScrolly(macarte) {
    mscrolly.init(macarte);
    macarte.zoomControl.remove();
    macarte.dragging.disable();
    if (isTouchDevice()) {
        macarte.doubleClickZoom.disable();
    }
}


var mscrolly = {
    infosClass: ".js-coord-point",
    sectionClass: ".js-section",
    sliderClass: ".js-bloc-relation-scrolly",
    cardStep: ".js-card-step",
    defaultBtn: "default",
    newBtn: "hover",
    map: null,
    coords: [],
    markers: [],
    infoWindows: {},
    markersSlider: [],
    coordsSlider: [],
    cards: [],
    lastOnMarker: null,
    lastSliderMarkers: [],
    sliderMarkerColor: 'in',
    stopClignote: false,
    visiblePoi: false,
    layerColor: "#76BE9B",
    layerWeight: 6,
    coordsTrace: [],
    gpxData: {
        coordLat: [],
        coordLng: [],
        coordAlt: []
    },
    lastPolylineDrawn: null,

    init: function (map) {
        if ($(".js-scrolly-iti").length > 0) {
            mscrolly.map = map;
            mscrolly.initMarkers();
            mscrolly.scrolly();
            mscrolly.placeGpx();
            mscrolly.sliders();
            mscrolly.initHoverSlider();
            mscrolly.parseBtns();

            if (window.location.href.indexOf("?checklatlng") > -1) {
                mscrolly.map.on('click', mscrolly.onMapClick);
            }
        }
    },


    initMarkers: function () {
        var el = $('.js-scrolly-iti #mapScrolly');

        // créé un marker de base.
        if (el.attr("data-lat") !== '') {
            var latLng = {
                lat: el.attr('data-lat') * 1,
                lng: el.attr('data-lng') * 1
            };

            if (latLng["lat"] !== 0 && latLng["lng"] !== 0) {
                mscrolly.markers[0] = th_maps.createMarker(mscrolly.map, latLng, mscrolly.newBtn);
            }
        }

        // trouve tous les points de la div mscrolly.infosClass
        $(mscrolly.infosClass).each(function (index, el) {
            mscrolly.createMarker(el.getAttribute('data-iteration') * 1, el);
        });

        // Ajout une modal sur les points des sections
        $(mscrolly.cardStep).each(function (id, el) {
            mscrolly.addCardStep(id, el);
        });
    },

    createMarker: function (id, el) {
        var latLng = {
            lat: el.getAttribute('data-lat') * 1,
            lng: el.getAttribute('data-lng') * 1
        };
        if (latLng["lat"] == 0 || latLng["lng"] == 0) {
            //on ne rajoute pas les points mal configuré
            return null;
        }
        mscrolly.coords[id] = el;
        mscrolly.markers[id] = th_maps.createMarker(mscrolly.map, latLng, mscrolly.defaultBtn);
        //TODO rajouter une condition pour cibler le scrolly v1
        // mscrolly.markers[id].on('click', mscrolly.goTo);
    },

    onMapClick: function (e) {
        //spécial checklatlng pour aider les infos.
        console.log(e.latlng);
    },

    initHoverSlider: function () {
        // ajoute les evenements mouseenter et mouseleave aux th-slider
        $(".th-slider a").each(function (index, el) {
            $(this).mouseenter(function () {
                $("#" + $(this).data("slide-id")).addClass("sliderFlash");
            });
            $(this).mouseleave(function () {
                $("#" + $(this).data("slide-id")).removeClass("sliderFlash");
                mscrolly.sliderMarkerColor = "in";
            });
        });
    },


    goTo: function (info) {
        // scroll jusqu'au point cliqué sur la map.
        target = $("[data-lat='" + info.latlng.lat + "'][data-lng='" + info.latlng.lng + "']")
        $('html, body').animate({scrollTop: target.offset().top - (window.innerHeight / 2)}, 'slow');
    },

    //TODO rajouter une condition pour cibler le scrolly v2
    gotToCardSection: function (info) {
        // scroll jusqu'au point cliqué sur la map.
        target = $("[data-lat='" + info.latlng.lat + "'][data-lng='" + info.latlng.lng + "']")
        $('html, body').animate({scrollTop: target.offset().top}, 'slow');
    },

    //TODO rajouter une condition pour cibler le scrolly v1
    // j'ai commenté pour pouvoir faire le fullcarte mais il faudra rajouter une condition pour cibler le scrolly full carte
    scrolly: function () {
        // observeur sur la mscrolly.infosClass
        // dès qu'il est à 50% du viewport
        //  - Soit pas de latlng alors reset
        //  - Soit reset des icons et changement de l'icon cible + bound autour du point cible.
        var els = document.querySelectorAll(mscrolly.infosClass);

        var options = {
            threshold: 0.9
        }

        var callback = function (entries) {

            for (var k in entries) {
                if (entries[k].isIntersecting || entries[k].isVisible) {
                    var latlng = {
                        lat: entries[k].target.getAttribute("data-lat") * 1,
                        lng: entries[k].target.getAttribute("data-lng") * 1
                    };
                    var id = entries[k].target.getAttribute('data-iteration') * 1;
                    var zoomLevel = entries[k].target.getAttribute("data-zoom") * 1;
                    if (latlng["lat"] == 0 && latlng["lng"] == 0) {
                        mscrolly.reset();
                    } else {
                        mscrolly.changeIcons(id, mscrolly.boundsMap(latlng, zoomLevel));
                        mscrolly.lastOnMarker = latlng;
                    }
                }
            }
        };

        var observer = new IntersectionObserver(callback, options);

        els.forEach(function (el) {
            observer.observe(el);
        })
    },

    changeIcons: function (idCible) {
        //reset tous les icons
        for (marker in mscrolly.markers) {
            mscrolly.markers[marker].setIcon(th_maps.markersIcons[mscrolly.defaultBtn]);
        }

        //met le nouvel icon à la cible
        mscrolly.markers[idCible].setIcon(th_maps.markersIcons[mscrolly.newBtn]);
    },


    // modal card pour les step
    addCardStep: function (id, el) {
        latlng = {
            lat: el.getAttribute("data-lat") * 1,
            lng: el.getAttribute("data-lng") * 1
        };

        var newElcardStep = $(el);
        newElcardStep.find("img").attr('src', newElcardStep.find("img").attr('data-src')).removeClass("lazy");
        var cardStep_html = newElcardStep.get(0).outerHTML;

        var icon = L.divIcon({
            className: 'icon',
            html: "<div></div>",
            iconSize: [30, 30],
            iconAnchor: [20, 20]
        });

        if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
            if (mscrolly.coordsSlider[id] == undefined) {
                mscrolly.coordsSlider[id] = newElcardStep;
                mscrolly.markersSlider[id] = th_maps.createMarker(mscrolly.map, latlng, icon).on('click', mscrolly.gotToCardSection);
                mscrolly.createInfoWindow(cardStep_html, mscrolly.markersSlider[id], 240);
            }
        }
    },

    createInfoWindow: function (content, marker, width) {
        if (!width) {
            width = 240;
        }

        width--;

        var infoWindow = L.popup({
            className: 'custom-popup-thuria',
            maxWidth: width,
            minWidth: width
        }).setContent(content);

        th_maps.infoWindows.push(infoWindow);

        if (marker) {
            marker.bindPopup(infoWindow);

            marker.addEventListener('click', function () {
                mscrolly.parseBtns();
                infoWindow.openPopup();
            });
        }
    },

    boundsMap: function (latlng, zoom) {
        if (zoom == null) {
            zoom = 17;
        }
        //TODO rajouter une condition pour cibler le scrolly v1
        if (!mscrolly.visiblePoi) {
            mscrolly.map.flyTo(latlng, zoom);
        }
    },

    sliders: function () {
        // interseciton Observer pour le bloc-relation-scrolly
        // Sert à l'affichage des cards quand elles sont à l'écran.
        var els2 = document.querySelectorAll(mscrolly.sliderClass);
        var options2 = {
            // pour up quand la cible rentre et sort.
            // threshold: [0, 0.1]
            threshold: 1
        }

        var callback2 = function (entries2, observer) {
            for (var k in entries2) {
                var id = entries2[k].target.getAttribute("id");
                var slider = $('#' + id);
                var firstTick = false;
                if (slider.attr('data-visible') == null) {
                    slider.attr('data-visible', 'false');
                    firstTick = true;
                } else {
                    if (slider.attr('data-visible') == 'false') {
                        slider.attr('data-visible', 'true');
                    } else {
                        slider.attr('data-visible', 'false');
                    }
                }

                if (!firstTick) {
                    if (slider.attr('data-visible') == 'true') {
                        mscrolly.visiblePoi = true;
                        mscrolly.lastSliderMarkers = [];
                        $("#" + id).find(".card").each(function (index, el) {
                            var caption = el.getElementsByClassName("caption")[0];
                            if (caption) {
                                var letter = caption.getAttribute('data-letter');
                                var picto = caption.getElementsByClassName('fichetype')[0].innerHTML;
                                mscrolly.addSliderMarker(id + index, el, letter, picto);
                            }
                        });

                        //on rajoute un flyBound
                        if (mscrolly.lastOnMarker) {
                            mscrolly.lastSliderMarkers.push(mscrolly.lastOnMarker);
                        }
                        var markerBounds = L.latLngBounds(mscrolly.lastSliderMarkers);
                        mscrolly.map.flyToBounds(markerBounds, {padding: [20, 20]});
                        //  /on rajoute un flyBound
                    } else {
                        mscrolly.visiblePoi = false;
                        $("#" + id).find(".card").each(function (index, el) {
                            mscrolly.removeSliderMarker(id + index);
                        });
                        //on remet le flyBound au bon endroit.
                        if (mscrolly.lastOnMarker) {
                            mscrolly.boundsMap(mscrolly.lastOnMarker);
                        }
                    }
                }
            }
        };

        var observer = new IntersectionObserver(callback2, options2);

        els2.forEach(function (el) {
            observer.observe(el);
        })
    },

    reset: function () {
        for (marker in mscrolly.markers) {
            mscrolly.markers[marker].setIcon(th_maps.markersIcons[mscrolly.defaultBtn]);
        }

        var latLngs = [];

        $(mscrolly.infosClass).each(function (index, el) {
            if (el.getAttribute("data-lat")) {
                latlng = {lat: el.getAttribute("data-lat") * 1, lng: el.getAttribute("data-lng") * 1};
            }
            if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
                latLngs.push(latlng);
            }
        });

        //todo: $(".ltngDeBase")
        // recuperer les data lat lng
        // latLngs.push(latlng);


        var markerBounds = L.latLngBounds(latLngs);
        mscrolly.map.flyToBounds(markerBounds, {padding: [20, 20]});
    },

    addSliderMarker: function (id, el, letter, picto) {
        latlng = {lat: el.getAttribute("data-lat") * 1, lng: el.getAttribute("data-lng") * 1};
        mscrolly.lastSliderMarkers.push(latlng);

        var newEl = $(el);
        newEl.find("img").attr('src', newEl.find("img").attr('data-src')).removeClass("lazy");
        var card_html = newEl.get(0).outerHTML;

        if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
            var icon = L.divIcon({
                className: 'blocpush-icon',
                html: "<div class='index-key' id='" + el.getAttribute("data-slide-id") + "'>" + letter + "</div><div class='typePicto'>" + picto + "</div>",
                iconSize: ["", ""],
                iconAnchor: [20, 20]
            });


            if (mscrolly.coordsSlider[id] == undefined) {
                //premiere création du marker.
                mscrolly.coordsSlider[id] = el;
                mscrolly.markersSlider[id] = th_maps.createMarker(mscrolly.map, latlng, icon).on('click', mscrolly.onMapClick);
                th_maps.createInfoWindow(card_html, mscrolly.markersSlider[id], 240);
            } else {
                //les markers ont déjà été créé on les remets dans le layer.
                mscrolly.addSliderMarkerSpe(latlng);
            }
        }
    },

    addSliderMarkerSpe: function (latlng) {
        //sous fonction pour rendre visible un point précis déjà existant.
        th_maps.markers.forEach(function (marker) {
            var latlng2 = marker.getLatLng();
            if (latlng2.lat == latlng["lat"] && latlng2.lng == latlng["lng"]) {
                mscrolly.map.addLayer(marker);
            }
        });
    },

    removeSliderMarker: function (id) {
        // sous fonction pour retirer un point visible sur la carte.
        if (mscrolly.markersSlider[id]) {
            mscrolly.map.removeLayer(mscrolly.markersSlider[id]);
        }
    },

    placeGpx: function () {
        mscrolly.gpxUrl = $('.js-map-scrolly .maps').attr('data-st-gpx');

        if (!mscrolly.gpxLayer && mscrolly.gpxUrl) {
            // Création du GPX
            mscrolly.gpxLayer = omnivore.gpx(mscrolly.gpxUrl).on("ready", function (e) {
                var highlightStyle = {
                    weight: 3,
                    opacity: 1,
                    color: "#000000",
                    //dashArray:"6,6"
                };
                this.setStyle(highlightStyle);

                this.eachLayer(function (layer) { // parcours de chaque layers car la clé du layer diffère à chaque chargement (oui...)
                    if (mscrolly.coordsTrace.length === 0) {
                        mscrolly.coordsTrace = layer._latlngs;
                    }
                });

                mscrolly.getGpxData();
                mscrolly.map.fitBounds(e.target.getBounds());
            }).addTo(mscrolly.map);
        }
    },

    getGpxData: function () {
        var geo = mscrolly.gpxLayer.toGeoJSON();
        $.each(geo.features, function (k, v) {
            if (v.geometry.type == 'LineString') {
                if (v.geometry.coordinates.length > 49) {
                    var number = Math.round(v.geometry.coordinates.length / 49);
                } else {
                    var number = false;
                }
                $.each(v.geometry.coordinates, function (k, v) {
                    if (number) {
                        if (k % number == 0) {
                            mscrolly.gpxData.coordLng.push(v[0]);
                            mscrolly.gpxData.coordLat.push(v[1]);
                            mscrolly.gpxData.coordAlt.push(v[2]);
                        }
                    } else {
                        mscrolly.gpxData.coordLng.push(v[0]);
                        mscrolly.gpxData.coordLat.push(v[1]);
                        mscrolly.gpxData.coordAlt.push(v[2]);
                    }
                });
            }
        });

        mscrolly.addTraces();
    },

    parseBtns: function () {
        const etapes = document.querySelector(".js-content-etapes");
        const btnOpenOverlay = document.querySelectorAll(".js-btn-open-overlay");

        $(btnOpenOverlay).each(function () {
            if (!this.classList.contains('parsed')) {

                $(this).on('click', function () {

                    if ($(etapes).hasClass("open")) {
                        $(etapes).removeClass("open");
                        mscrolly.openOverlay();
                    } else {
                        $(etapes).addClass("open");
                        mscrolly.openOverlay();
                    }
                });

                this.classList.add('parsed')
            }
        });
    },

    openOverlay: function () {
        const map = document.querySelector(".js-map-scrolly");
        if ($(map).hasClass("open")) {
            $(map).removeClass("open");
        } else {
            $(map).addClass("open");
        }
    },

    // scrolly full carte
    addTraces: function () {
        const coordsEtapes = [];
        const etapeDistance = [];
        const tracesFinaux = [];
        let lastIndexTrace = 0;
        //let lastIndexOfLastTrace = 0;

        // trouve toutes les coordonnées des étapes
        [...document.querySelectorAll(mscrolly.infosClass)].forEach(function (el) {
            const latlng = {lat: el.getAttribute("data-lat") * 1, lng: el.getAttribute("data-lng") * 1};

            coordsEtapes.push({
                latlng
            });
        });

        coordsEtapes.forEach(function (etape, indexEtape) {
            mscrolly.coordsTrace.forEach(function (coordsTraceGpx, indexTrace) {
                const fromLatLng = L.latLng(etape.latlng.lat, etape.latlng.lng);
                const toLatLng = L.latLng(coordsTraceGpx.lat, coordsTraceGpx.lng);
                const dis = fromLatLng.distanceTo(toLatLng);

                if (!etapeDistance[indexEtape]) {
                    etapeDistance[indexEtape] = {
                        index: indexTrace,
                        distance: dis
                    };
                } else {
                    if (dis < etapeDistance[indexEtape].distance && (lastIndexTrace < indexTrace || !lastIndexTrace)) {
                        etapeDistance[indexEtape].distance = dis;
                        etapeDistance[indexEtape].index = indexTrace;

                        lastIndexTrace = indexTrace;
                    }
                }
            });
        });

        etapeDistance.forEach(function (etape, etapeIndex) {
            // Création du tracé
            const newTrace = [];

            // Variable pour le dernier index correspondant à la fin du tracé current
            let endIndex = 0;

            // Condition pour définir si on est sur le dernier tracé de la boucle
            // Si oui, on récupère le dernier index du tracé
            // Si non, on récupère le début du prochain tracé (qui correspond au tracé suivant)
            if (etapeDistance.length === etapeIndex + 1) {
                endIndex = mscrolly.coordsTrace.length - 1;
            } else {
                endIndex = etapeDistance[etapeIndex + 1].index;
            }

            // On boucle sur le tracé complet, en commencant par l'index de départ jusqu'à la fin du tracé current
            for (let i = etapeDistance[etapeIndex].index; i < endIndex; i++) {
                newTrace.push([mscrolly.coordsTrace[i].lat, mscrolly.coordsTrace[i].lng]);
            }

            // On pousse le tracé actuel dans un tableau
            tracesFinaux.push(newTrace);
        })


        // récupérer la hauteur des différentes étapes/sections
        let sectionClassArray = [];

        // boucle sur les sections pour récupérer la hauteur, le top et le bottom
        $(mscrolly.sectionClass).each(function (index, el) {
            const sectionClassHeight = el.clientHeight;
            const sectionClassOffsetTop = el.offsetTop;
            // le bas de la div est égal au top + la hauteur
            const sectionClassOffsetBottom = sectionClassOffsetTop + sectionClassHeight;

            sectionClassArray.push({
                top: sectionClassOffsetTop,
                bottom: sectionClassOffsetBottom,
                height: sectionClassHeight,
                //   index: index,
                trace: L.polyline([], {
                    color: mscrolly.layerColor,
                    weight: mscrolly.layerWeight
                })
            });
        });

        const coverScrolly = document.querySelector(".js-wrapper-coverscrolly");

        // écouter le scroll sur le contenu total et regarder pour avoir 0 à 100%
        window.addEventListener("scroll", function () {
            let scrollY = window.scrollY; // position dans la page
            let containerPos = coverScrolly.getBoundingClientRect().bottom + scrollY;  // hauteur de la cover

            if (scrollY >= containerPos) {
                for (let i = 0; i < sectionClassArray.length; i++) {

                    if (scrollY > sectionClassArray[i].top) {

                        // TODO : Boucler sur toutes les étapes précédentes pour compléter leur tracé
                        /*   for (let j = 0; j < i; j++) {
                               const newTraceCoords = [];
                               if (j < i) {
                                   let percentToIndex = Math.ceil(100);
                                   console.log(percentToIndex);

                                   for (let j = 0; j < percentToIndex; j++) {
                                       newTraceCoords.push([tracesFinaux[i][j][0], tracesFinaux[i][j][1]]);
                                   }

                                   newTraceCoords.push([tracesFinaux[i][j][0], tracesFinaux[i][j][1]]);

                                   sectionClassArray[j].trace = L.polyline(newTraceCoords, {
                                       color: mscrolly.layerColor,
                                       weight: mscrolly.layerWeight
                                   });

                                   mscrolly.map.addLayer(sectionClassArray[j].trace);
                               }
                           } */

                        // TODO : Boucler sur toutes suivantes pour remove leur tracé
                        // TODO : Sur la dernière étape, booster la height
                        // TODO : voir pour convertir les intersectionObserver pour activer le marker correspondant quand on arrive dans l'étape

                        let percentScrolledInSection = ((scrollY - sectionClassArray[i].top) / sectionClassArray[i].height) * 100;

                        if (percentScrolledInSection >= 0 && percentScrolledInSection <= 100) {
                            let percentToIndex = Math.ceil((percentScrolledInSection * tracesFinaux[i].length) * 0.01);
                            if (sectionClassArray[i].trace) {
                                mscrolly.map.removeLayer(sectionClassArray[i].trace);
                            }

                            const newTraceCoords = [];

                            for (let j = 0; j < percentToIndex; j++) {
                                newTraceCoords.push([tracesFinaux[i][j][0], tracesFinaux[i][j][1]]);
                            }

                            sectionClassArray[i].trace = L.polyline(newTraceCoords, {
                                color: mscrolly.layerColor,
                                weight: mscrolly.layerWeight
                            });

                            mscrolly.map.addLayer(sectionClassArray[i].trace);
                            mscrolly.map.fitBounds([[newTraceCoords[newTraceCoords.length - 1][0], newTraceCoords[newTraceCoords.length - 1][1]], [newTraceCoords[newTraceCoords.length - 1][0], newTraceCoords[newTraceCoords.length - 1][1]]]);
                        }
                    }
                }
            }
        })
    }
};


