var whereIsTheSearchBar = 'inmap';

$(window).on('resize',replaceSearchCityBarAlgoliaAndSetStickyMode);

function replaceSearchCityBarAlgoliaAndSetStickyMode(){

    if(isTabletPortraitOrSmalller()){
        listingMapManager.setMode('mobile');
    }else{
        listingMapManager.setMode('desktop');
    }

    if($('.page-listing-lei').length > 0){
        if(!isTabletPortraitOrSmalller() && whereIsTheSearchBar != 'inmap'){
            $('.bloc-map.map').append($('.algolia-place'));
            whereIsTheSearchBar = 'inmap';
        }
        if(isTabletPortraitOrSmalller() && whereIsTheSearchBar != 'infilter'){
            $('.overlay-filtre .content').prepend($('.algolia-place')[0]);
            whereIsTheSearchBar = 'infilter';
        }
    }
}


function callbackMapListing(macarte){
  macarte.zoomControl.setPosition('bottomleft');
}


thBlocs.add('pagelisting',function(){
    if($('.page-listing-lei').length > 0) {

        debug('INIT bloc : page listing lei');

        whereIsTheSearchBar = 'inmap';
        replaceSearchCityBarAlgoliaAndSetStickyMode();


        algoliaFacette.init();
        if(!isTabletPortraitOrSmalller()) {
            var map = th_maps.getMap($('.maps')[0]);
            if(map){ map.invalidateSize(); }
        }
        listingMapManager.init();
    }
});
function updateListingStickyEls(){
  if(!isTabletPortraitOrSmalller()) {
    var map = th_maps.getMap($('.maps')[0]);
    if(map){ map.invalidateSize(); }
  }

}