
if (typeof navigator.serviceWorker != 'undefined') {
    navigator.serviceWorker.register("/sw-cache.js");
};

setInterval(updateCacheListContent,1000);

function updateCacheListContent(){
    if($('#cache-list-content:not(.parsed)').length > 0){
        $('#cache-list-content').addClass('parsed');
        caches.open('page.v0.1.5').then(
            function(cache){cache.matchAll().then(
                function(responses){
                    var html = '';
                    $.each(responses,function(k,response){
                        if(!response.url.match(/\/offline\/$/)){
                            $.get(response.url,function(html){
                                if(html){
                                    var title = html.match(/\<title\>(.*)\<\/title\>/);
                                    if(title){
                                        title = title[1].split('|');
                                        $('#cache-list-content ul').append('<li><a href="'+response.url+'">'+title[0]+'</a></li>');
                                    }
                                }
                            },'text');
                        }
                    });
                    $('#cache-list-content').html('<ul></ul>');
                }
            )}
        );
    }

}