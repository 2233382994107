var algoliaFacette = {
    queries: {},
    staticQueries: [],

    algoliaClient: null,
    algoliaIndex: null,

    geoQuery: null,
    dateQuery: null,

    pageQuery: 0,

    timeoutUpdateDate: null,

    placesAutocomplete: null,

    preventHashchange: false,
    preventUpdateResult: false,
    preventUpdateUrl: false,

    geocodingRequest: 0,
    geocodingInput: null,
    geocodingResults: null,

    init: function () {

        debug('INIT : Listing LEI Algolia');

        $('.pagination .btn-next').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            algoliaFacette.setPage(algoliaFacette.pageQuery + 2);
        });
        $('.pagination .prev').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            algoliaFacette.setPage(algoliaFacette.pageQuery);
        });


        algoliaFacette.handleCitySearch();


        // partie overlay
        $('ul > li', '#overlay-filtre-lei').each(function () {

            var id = $(this).attr('id');
            if (id == 'date_duau') {
                return;
            }

            var queries = {normal: [], multi: [], static: []};

            $('.query-normal', this).each(function () {
                queries.normal.push({
                    group: $(this).data('group'),
                    relation: $(this).data('relation'),
                    query: $(this).text()
                });
                $(this).remove();
            });
            $('.query-multi', this).each(function () {
                queries.multi.push({
                    group: $(this).data('group'),
                    relation: $(this).data('relation'),
                    query: $(this).text()
                });
                $(this).remove();
            });

            algoliaFacette.queries[id] = queries;

            $(this).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                setTimeout(function () {
                    algoliaFacette.handleSelect(e.currentTarget);
                }, 50);
            });
        });


        $('#tags li').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            setTimeout(function () {
                algoliaFacette.handleSelect(e.currentTarget);
            }, 50);
        });


        // partie filtre afficher dans la bar
        $('.wrapper-listing-lei .bloc-filtres .switch, .listing-lei-iti .bloc-filtres .switch').each(function () {

            $(this).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('ul > li[data-slug="' + $(this).data('slug') + '"]', '#overlay-filtre-lei').click();
            });
        });


        // partie filtre afficher en petit sous les gros tags
        $('a[href="#RemoveFiltres"]').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            algoliaFacette.resetFilter();
        });

        // query static (défini le listing)
        $('.query-static').each(function () {
            algoliaFacette.staticQueries.push({
                group: 'static',
                relation: 'OR',
                query: $(this).text()
            });
            $(this).remove();
        });

        var time = new Date().getTime();


        if ($('#overlay-filtre-lei .date input[type="date"]').length > 0) {
            var $el_date_du = $('#overlay-filtre-lei .date input[type="date"]').first();
            var $el_date_au = $('#overlay-filtre-lei .date input[type="date"]').last();
            var pikadayDu, pikadayAu;

            $el_date_du.attr('name', 'date_' + time);
            $el_date_du.attr('autocomplete', 'off');

            $el_date_au.attr('name', 'date_' + time);
            $el_date_au.attr('autocomplete', 'off');

            if (!isNoHover()) {
                $el_date_du.attr('type', 'text');
                $el_date_au.attr('type', 'text');
                $el_date_au.attr('value', $el_date_au.attr('value').split('-').reverse().join('/'));
                $el_date_du.attr('value', $el_date_du.attr('value').split('-').reverse().join('/'));

                pikadayDu = new Pikaday({field: $el_date_du.get(0), format: 'DD/MM/YYYY', firstDay: 1});
                pikadayAu = new Pikaday({
                    field: $el_date_au.get(0),
                    format: 'DD/MM/YYYY',
                    firstDay: 1,
                });
                if (pikadayDu.getDate()) {
                    pikadayAu.setMinDate(pikadayDu.getDate());
                }

                $el_date_du.on('change', function () {
                    pikadayAu.setMinDate(pikadayDu.getDate());
                    if (!pikadayAu.getDate() || pikadayDu.getDate().getTime() > pikadayAu.getDate().getTime()) {
                        pikadayAu.setDate(new Date(pikadayDu.getDate().getTime() + 1000 * 60 * 60 * 24));
                    }
                    algoliaFacette.updateDateQuery();
                });

                $el_date_au.on('change', function () {
                    algoliaFacette.updateDateQuery();
                });
            } else {
                $el_date_du.on('change', function () {
                    algoliaFacette.updateDateQuery();
                });
                $el_date_au.on('change', function () {
                    algoliaFacette.updateDateQuery();
                });
            }
        }


        algoliaFacette.parseDisplayedResult();

        algoliaFacette.algoliaClient = algoliasearch(thConfig.algolia.id, thConfig.algolia.key);
        algoliaFacette.algoliaIndex = algoliaFacette.algoliaClient.initIndex('lei_' + thLang.current);

        var hash = document.location.hash.replace('#', '');
        var baseUrl = document.location.href;

        var matchPage = baseUrl.match(/\/page\/([0-9]+)\/$/);
        if (matchPage) {
            var pageNumber = parseInt(matchPage[1]);
            algoliaFacette.pageQuery = pageNumber - 1;
        }
        if (hash) {

            hashListPage = hash.split('/');
            hashList = hashListPage[0].split('_');

            if (hashList.length == 0 || (hashList.length == 1 && hashList[0] == '')) {
                $('#tags li.date-query').each(function () {
                    $(this).remove();
                    algoliaFacette.updateDateQueryNoTimeout(true);
                });
            } else {
                $('#tags li.date-query').remove();
            }

            algoliaFacette.updateByHash(hash);


        } else {
            $('.pagination-number .list a').each(function () {
                $(this).attr('onclick', 'algoliaFacette.setPage(' + this.innerHTML + '); return false;');
            });
        }

        window.addEventListener("hashchange", function () {
            if (!algoliaFacette.preventHashchange) {
                setTimeout(function () {
                    algoliaFacette.updateByHash(document.location.hash.replace('#', ''));
                }, 50);
            }
        }, false);

    },


    updateByHash: function (hash, isInit) {
        var $el_date_du = $('#overlay-filtre-lei .date input').first();
        var $el_date_au = $('#overlay-filtre-lei .date input').last();

        hashListPage = hash.split('/');
        hashList = hashListPage[0].split('_');

        algoliaFacette.preventUpdateResult = true;
        algoliaFacette.preventUpdateURL = true;

        $('#tags li[data-slug]').remove();

        var pageNumber = 1;
        if (hashListPage.length > 1) {
            pageNumber = parseInt(hashListPage[1]);
        }
        algoliaFacette.pageQuery = pageNumber - 1;

        $.each(hashList, function (k, slug) {
            var matchs = slug.match(/^(date\[)([0-9]{1,2}\-[0-9]{1,2}\-[0-9]{1,4})\]\[([0-9]{1,2}\-[0-9]{1,2}\-[0-9]{1,4})\]/);
            if (matchs) {
                var dateDu = matchs[2];
                var dateAu = matchs[3];

                if (isTouchDevice()) {
                    if (dateDu) {
                        $el_date_du.attr('value', dateDu.split("-").reverse().join("-"));
                    }

                    if (dateAu) {
                        $el_date_au.attr('value', dateAu.split("-").reverse().join("-"));
                    }
                } else {
                    $el_date_du.attr('value', dateDu.split('-').join('/'));
                    $el_date_au.attr('value', dateAu.split('-').join('/'));
                }
                algoliaFacette.updateDateQueryNoTimeout(true);
            } else {
                algoliaFacette.setItemSelected(slug);
            }
        });

        algoliaFacette.preventUpdateResult = false;
        algoliaFacette.refreshResult();
        algoliaFacette.preventUpdateURL = false;
    },

    handleSelect: function (el) {
        if ($(el).parents('#tags').length > 0 || $('input:checked', el).length > 0) {
            algoliaFacette.setItemUnselected(el);
        } else {
            algoliaFacette.setItemSelected(el);
        }
        algoliaFacette.pageQuery = 0;
        algoliaFacette.refreshResult();
    },

    getEl: function (el_or_slug) {
        var el;
        if (typeof el_or_slug != 'string') {
            el = el_or_slug;
        } else {
            el = $('[data-slug="' + el_or_slug + '"]')[0];
        }
        return el;
    },

    resetFilter: function () {
        $('#tags li').click();

        algoliaFacette.placesAutocomplete.setVal('');
        algoliaFacette.geoQuery = null;
        algoliaFacette.refreshResult();
        listingMapManager.setMarkerPlace(0, 0);
        $('.geo-loc > span').attr('class', 'icon-ico-position');

    },

    setItemSelected: function (el_or_slug) {
        var el = algoliaFacette.getEl(el_or_slug);
        if (el) {
            setTimeout(function () {
                $('li[data-slug="' + $(el).data('slug') + '"] input').prop("checked", true);
            }, 50);
            var li = document.createElement('li');
            $.each(el.dataset, function (key, val) {
                li.setAttribute('data-' + key, val);
            });
            li.setAttribute('data-id', el.id);

            li.innerHTML = $('label', el).html();
            li.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                algoliaFacette.handleSelect(li);
            });
            $('#tags').append(li);
        }
    },

    setItemUnselected: function (el_or_slug) {
        var el = algoliaFacette.getEl(el_or_slug);
        $('#tags li[data-slug="' + el.getAttribute('data-slug') + '"]').remove();
        $('#tags-mobile li[data-slug="' + el.getAttribute('data-slug') + '"]').remove();
        setTimeout(function () {
            $('li[data-slug="' + el.getAttribute('data-slug') + '"] input').prop("checked", false);
        }, 50);
    },

    getQueriedFacettes: function () {
        var list = [];
        $('ul#tags > li').each(function () {
            if ($(this).attr('data-id')) {
                list.push(($(this).attr('data-id')).replace('-avant-', '-'));
            }
        });

        return list;
    },

    getCurrentQueries: function () {
        var list = algoliaFacette.getQueriedFacettes();
        var queries = {normal: [], multi: []};
        $.each(list, function (k, id) {
            if (algoliaFacette.queries[id]) {
                $.each(algoliaFacette.queries[id].normal, function (k, query) {
                    queries.normal.push(query);
                });
                $.each(algoliaFacette.queries[id].multi, function (k, query) {
                    queries.multi.push(query);
                });
            }
        });

        return queries;
    },

    getQuery: function () {
        var queries = algoliaFacette.getCurrentQueries();

        var queriesByGroup = {};
        var queriesByGroupRelation = {};

        $.each(queries.normal, function (k, query) {
            if (!queriesByGroup[query.group]) {
                queriesByGroup[query.group] = [];
                queriesByGroupRelation[query.group] = query.relation;
            }
            queriesByGroup[query.group].push(query.query);
        });
        $.each(queries.multi, function (k, query) {
            if (!queriesByGroup[query.group]) {
                queriesByGroup[query.group] = [];
                queriesByGroupRelation[query.group] = query.relation;
            }
            queriesByGroup[query.group].push(query.query);
        });
        $.each(algoliaFacette.staticQueries, function (k, query) {
            if (!queriesByGroup[query.group]) {
                queriesByGroup[query.group] = [];
                queriesByGroupRelation[query.group] = query.relation;
            }
            queriesByGroup[query.group].push(query.query);
        });

        var listOfGroupQuery = [];
        $.each(queriesByGroup, function (group, queryList) {
            listOfGroupQuery.push(queryList.join(' ' + queriesByGroupRelation[group] + ' '));
        });

        $.each(algoliaFacette.dateQuery, function (k, query) {
            listOfGroupQuery.push(query);
        });

        if (listOfGroupQuery.length == 0) {
            return '';
        }
        return '(' + listOfGroupQuery.join(') AND (') + ')';
    },

    setPage: function (p) {
        algoliaFacette.pageQuery = p - 1;
        algoliaFacette.refreshResult();
    },

    refreshResult: function () {
        if (algoliaFacette.preventUpdateResult) {
            return false;
        }

        var offset = $('.list-results').offset();
        $([document.documentElement, document.body]).animate({
            scrollTop: offset.top - 120
        }, 250, function () {
            updateListingStickyEls();
        });

        var filters = algoliaFacette.getQuery();

        var requestData = {"hitsPerPage": 18, filters: filters};
        if (algoliaFacette.geoQuery) {
            requestData.aroundLatLng = algoliaFacette.geoQuery.aroundLatLng;
            requestData.aroundRadius = algoliaFacette.geoQuery.aroundRadius;
            requestData.getRankingInfo = true;
        }
        if (algoliaFacette.pageQuery > 0) {
            requestData.page = algoliaFacette.pageQuery;
        }

        algoliaFacette.updateUrl();
        algoliaFacette.algoliaIndex.search(requestData, function (err, response) {
            if (!err) {

                $('#nbhits').text(response.nbHits);
                $('#result-count-total').text(response.nbHits);

                var htmlContent = '';
                $.each(response.hits, function (k, fiche) {
                    htmlContent += cardGenerator.generate(fiche);
                });
                $('#result').html(htmlContent);

                var paginationHTML = '';
                var lastIsHidden = false;
                for (var i = 0; i != response.nbPages; i++) {
                    var p = i + 1;


                    // 1 2 3 ... 37 38
                    // 1 2 3 ... 11 12 13 ... 37 38

                    // on affiche pas
                    // Si il y a plus de 6 page
                    // Si la page P est supérieur a 3
                    // Si P n'est pas la page en cours -1
                    // SI P est supérieur à la page en cours de plus d'un cran
                    // SI P n'est pas une des deux dernière page
                    if (response.nbPages > 5 && p > 2 && p - 1 != response.page && p < response.nbPages - 1) {
                        lastIsHidden = true;
                    } else {
                        if (lastIsHidden) {
                            lastIsHidden = false;
                            paginationHTML += '...';
                        }
                        if (i == response.page) {
                            paginationHTML += '<a class="active" href="#page-' + p + '" onclick="algoliaFacette.setPage(' + p + '); return false;">' + p + '</a>';
                        } else {
                            paginationHTML += '<a onclick="algoliaFacette.setPage(' + p + '); return false;" href="#page-' + p + '">' + p + '</a>';
                        }
                    }

                }

                if (response.page + 1 == response.nbPages) {
                    $('.pagination .btn-next').addClass('disabled');
                    $('.paginate-mobile .btn-next', '#overlay-map .data-bottom').addClass("disabled");
                } else {
                    $('.pagination .btn-next').removeClass('disabled');
                    $('.paginate-mobile .btn-next', '#overlay-map .data-bottom').removeClass("disabled");
                }
                if (response.page == 0) {
                    $('.pagination .prev').addClass('disabled');
                    $('.paginate-mobile .prev', '#overlay-map .data-bottom').addClass("disabled");
                } else {
                    $('.pagination .prev').removeClass('disabled');
                    $('.paginate-mobile .prev', '#overlay-map .data-bottom').removeClass("disabled");
                }

                $('.pagination-number .list').html(paginationHTML);
                $('#paginate-from').html((response.page * response.hitsPerPage) + 1);
                $('#paginate-to').html(((response.page + 1) * response.hitsPerPage) - (response.hitsPerPage - response.hits.length));
                $('#paginate-total').html(response.nbHits);

                updateListingStickyEls();


                algoliaFacette.parseDisplayedResult();

            }
        });


    },

    updateUrl: function () {
        if (algoliaFacette.preventUpdateURL) {
            return false;
        }
        var url = '';
        var slugs = [];
        $('#tags li[data-slug]').each(function () {
            slugs.push(this.dataset.slug);
        });


        if ($('#tags li.date-query').length > 0) {

            var $el_date_du = $('#overlay-filtre-lei .date input').first();
            var $el_date_au = $('#overlay-filtre-lei .date input').last();

            var du = $el_date_du.val();
            var au = $el_date_au.val();

            if (typeof du == 'undefined' || du == '' || !du) {
                du = '0-0-0';
            }
            if (typeof au == 'undefined' || au == '' || !au) {
                au = '0-0-0';
            }

            if (du !== '0-0-0' && !du.match(/\-/)) {
                du = du.split('/').join('-');
            }
            if (au !== '0-0-0' && !au.match(/\-/)) {
                au = au.split('/').join('-');
            }

            if (isTouchDevice()) {
                du = du.split('-').reverse().join('-');
                au = au.split('-').reverse().join('-');
            }

            slugs.push('date[' + du + '][' + au + ']');
        }

        url = slugs.join('_');

        if (algoliaFacette.pageQuery > 0) {
            url += '/' + (algoliaFacette.pageQuery + 1);
        }

        thPWA.tempDisable();

        algoliaFacette.preventHashchange = true;
        //window.location.hash = url;
        thPWA.prevState = {is_hashchange: url, url: current_href()};
        thPWA.prevUrlNoHash = current_href();
        thPWA.prevUrl = current_href() + '#' + url;
        window.history.pushState({is_hashchange: url, url: current_href()}, '', current_href() + '#' + url);

        setTimeout(function () {
            algoliaFacette.preventHashchange = false;
        }, 250);

        return url;
    },

    parseDisplayedResult: function () {

        setTimeout(function () {
            if (typeof listingMapManager.map != 'undefined' && listingMapManager.map) {
                listingMapManager.map.invalidateSize();
            }
        }, 2000);

        listingMapManager.updateFromListing();
        if (typeof updateOuvertureLEIID != 'undefined') {
            updateOuvertureLEIID();
        }
    },


    handleCitySearch: function () {
        algoliaFacette.geocodingInput = document.querySelector('#algolia-city-search');

        if (algoliaFacette.geocodingInput) {
            algoliaFacette.geocodingResults = document.querySelector(".algolia-place__results");
            var inputUnfocused = null;
            var currentValue = "";

            algoliaFacette.geocodingInput.addEventListener("focus", function () {
                if (this.value && inputUnfocused) {
                    this.value = "";

                    algoliaFacette.resetGeocodingRequest();
                }
            });

            algoliaFacette.geocodingInput.addEventListener("blur", function () {
                if (!inputUnfocused) {
                    inputUnfocused = true
                }

                if (!this.value) {
                    algoliaFacette.geocodingResults.innerHTML = "";
                }
            });

            algoliaFacette.geocodingInput.addEventListener("input", function () {
                if (this.value !== currentValue && this.value !== "") {
                    currentValue = this.value;
                    var _self = this;

                    algoliaFacette.geocodingRequest++;
                    var ajaxCount = algoliaFacette.geocodingRequest;

                    $.get("https://data.geopf.fr/geocodage/completion?text=" + this.value + "&type=StreetAddress,PositionOfInterest&terr=METROPOLE&maximumResponses=5", function (res) {
                        if (ajaxCount === algoliaFacette.geocodingRequest) {
                            algoliaFacette.geocodingResults.innerHTML = "";

                            if (res && res.results && res.results.length) {
                                $.each(res.results, function (index, value) {
                                    if (value.x !== 0 && value.y !== 0) {
                                        var el = document.createElement("li");

                                        var btn = document.createElement("button");
                                        btn.setAttribute("data-lat", value.y);
                                        btn.setAttribute("data-lng", value.x);
                                        btn.setAttribute("data-adresse", value.fulltext);
                                        btn.textContent = value.fulltext;

                                        btn.addEventListener("click", function (e) {
                                            e.preventDefault();

                                            // Mise à jour de la value du champ recherche
                                            _self.value = value.fulltext;

                                            // Envoi de la requête à algolia
                                            algoliaFacette.sendGeocodingRequest(value.x, value.y);
                                        });

                                        el.appendChild(btn);
                                        algoliaFacette.geocodingResults.appendChild(el);
                                    }
                                });
                            }
                        }
                    });
                }
            });


            // algoliaFacette.placesAutocomplete.on('clear', function () {
            //     lastValue = null;
            //     if (lastValueGeo == null) {
            //         algoliaFacette.geoQuery = null;
            //         algoliaFacette.setPage(1);
            //         // automatique avec set page : algoliaFacette.refreshResult();
            //         listingMapManager.setMarkerPlace(0, 0);
            //     }
            // });

            // algoliaFacette.placesAutocomplete.on('change', function (response) {
            //
            //     $('.geo-loc > span').attr('class', 'icon-ico-position');
            //     lastValueGeo = null;
            //     lastValue = response.suggestion;
            //     listingMapManager.move(lastValue.latlng.lat, lastValue.latlng.lng);
            //     listingMapManager.setMarkerPlace(lastValue.latlng.lat, lastValue.latlng.lng);
            //     algoliaFacette.geoQuery = {
            //         aroundLatLng: lastValue.latlng.lat + ', ' + lastValue.latlng.lng,
            //         aroundRadius: 15000
            //     };
            //     algoliaFacette.setPage(1);
            //     // automatique avec set page : algoliaFacette.refreshResult();
            // });


            $('.geo-loc').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (lastValueGeo === null) {
                    geoloc.getGeoloc(function (coord) {
                        $('.geo-loc > span').attr('class', 'icon-ico-gallery-cross');
                        el.value = thLang.get('maposition');
                        lastValueGeo = thLang.get('maposition');
                        listingMapManager.move(coord.lat, coord.lng);
                        listingMapManager.setMarkerPlace(coord.lat, coord.lng);
                        algoliaFacette.geoQuery = {
                            aroundLatLng: coord.lat + ', ' + coord.lng,
                            aroundRadius: 15000
                        };
                        algoliaFacette.setPage(1);
                        // automatique avec set page : algoliaFacette.refreshResult();
                    });
                } else {
                    algoliaFacette.geoQuery = null;
                    algoliaFacette.refreshResult();
                    listingMapManager.setMarkerPlace(0, 0);
                    lastValueGeo = null;
                    el.value = '';
                    $('.geo-loc > span').attr('class', 'icon-ico-position');
                }
            });
        }
    },

    sendGeocodingRequest: function (lat, lng) {
        algoliaFacette.geocodingResults.innerHTML = "";

        listingMapManager.move(lng, lat);
        listingMapManager.setMarkerPlace(lng, lat);

        algoliaFacette.geoQuery = {
            aroundLatLng: lng + ', ' + lat,
            aroundRadius: 15000
        };

        algoliaFacette.setPage(1);
    },


    resetGeocodingRequest: function () {
        algoliaFacette.geoQuery = null;
        listingMapManager.setMarkerPlace(0, 0);

        algoliaFacette.setPage(1);
    },

    updateDateQuery: function (doNotUpdateResults, timeoutDuration) {

        if (!timeoutDuration && timeoutDuration !== 0) {
            timeoutDuration = 100;
        }
        if (algoliaFacette.timeoutUpdateDate) {
            clearTimeout(algoliaFacette.timeoutUpdateDate);
        }

        algoliaFacette.timeoutUpdateDate = setTimeout(function () {
            algoliaFacette.updateDateQueryNoTimeout(doNotUpdateResults);
        }, timeoutDuration);

    },

    updateDateQueryNoTimeout: function (doNotUpdateResults) {

        var dateQuery = [];

        var $el_date_du = $('#overlay-filtre-lei .date input').first();
        var $el_date_au = $('#overlay-filtre-lei .date input').last();

        var du = $el_date_du.val();
        var au = $el_date_au.val();

        if (typeof du == 'undefined' || du == '' || !du) {
            du = null;
        }
        if (typeof au == 'undefined' || au == '' || !au) {
            au = null;
        }

        if (du !== null && du.match(/\-/)) {
            du = du.split('-').reverse().join('/');
        }
        if (au !== null && au.match(/\-/)) {
            au = au.split('-').reverse().join('/');
        }

        if (du !== null && du.match(/\//)) {
            var duTime = Math.round((new Date(du.split('/').reverse().join('-')).getTime()) / 1000);
            dateQuery.push('date_au >= ' + duTime);

        }

        if (au !== null && au.match(/\//)) {
            var auTime = Math.round((new Date(au.split('/').reverse().join('-')).getTime()) / 1000);
            dateQuery.push('date_du <= ' + auTime);
        }

        if (dateQuery != algoliaFacette.dateQuery) {
            algoliaFacette.dateQuery = dateQuery;

            if (algoliaFacette.dateQuery) {
                $('#tags .date-query').remove();
                var li = document.createElement('li');
                li.className = 'date-query';
                if (du && au) {
                    li.innerHTML = '<a href="#">Du ' + ($el_date_du.val().split('-').reverse().join('/')) + ' au ' + ($el_date_au.val().split('-').reverse().join('/')) + '</a>';
                } else if (du) {
                    li.innerHTML = '<a href="#">Du ' + ($el_date_du.val().split('-').reverse().join('/')) + '</a>';
                } else if (au) {
                    li.innerHTML = '<a href="#">Au ' + ($el_date_au.val().split('-').reverse().join('/')) + '</a>';
                }
                li.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    $el_date_du.val('');
                    $el_date_au.val('');
                    $(this).remove();
                    algoliaFacette.dateQuery = [];
                    algoliaFacette.setPage(1);
                });
                $('#tags').append(li);
            }
            if (doNotUpdateResults !== true) {
                algoliaFacette.setPage(1);
            }
        }
    }
};