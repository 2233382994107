function debug(var1,var2,var3){
    if(thConfig.debug == true){
        if(typeof var2 != 'undefined'){
            if(typeof var3 != 'undefined'){
                console.log(var1,var2,var3);
            }else{
                console.log(var1,var2);
            }
        }else{
            console.log(var1);
        }
    }
}