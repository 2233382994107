var th_overlayModified = {
    selector_overlay: '.overlay',
    selector_overlay_shadow: '.shadow-overlay',

    callbackOpen: [],
    callbackClose: [],

    preventDefault: true,
    noHashInUrl: false,

    init: function (settings) {
        th_overlayModified.parseOverlayShadow($('body'));
        th_overlayModified.parse($('body'));

        if (settings) {
            $.each(settings, function (key, setting) {
                if (th_overlayModified[key]) {
                    th_overlayModified[key] = setting;
                }
            });
        }
    },

    addCallbackOpen: function (callback) {
        th_overlayModified.callbackOpen.push(callback);
    },
    addCallbackClose: function (callback) {
        th_overlayModified.callbackClose.push(callback);
    },

    parseOverlayShadow: function () {
        $(th_overlayModified.selector_overlay_shadow).on('click', function (e) {
            e.preventDefault();
            th_overlayModified.close();
        });
    },

    parse: function (el) {

        $(th_overlayModified.selector_overlay).each(function () {
            var overlayId = this.id;
            var targetOpen = $('a[href="#' + overlayId + '"]:not(.parsed-overlay):not(.close), [data-overlay-open="' + overlayId + '"]:not(.parsed-overlay)');

            targetOpen.addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-open');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }
                    th_overlayModified.open(idOverlay);
                });

            $('a[href="#' + overlayId + '"].close:not(.parsed-overlay), [data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click', function (e) {
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-close');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    if (!idOverlay) {
                        idOverlay = null;
                    }

                    if (th_overlayModified.preventDefault) {
                        e.preventDefault();
                    }

                    th_overlayModified.close(idOverlay);
                });
        });
    },

    openOnLoad: function () {
        var current_hash = (window.location.hash).split('#');

        if (current_hash) {
            $.each(current_hash, function (index, overlay_id) {
                if (overlay_id && overlay_id.length) {
                    var corresponding_overlay = $("#" + overlay_id);

                    if ($(corresponding_overlay).hasClass("overlay")) {
                        return th_overlayModified.open(overlay_id);
                    }
                }
            });
        }
    },

    open: function (overlayId, openShadow, doCallback) {
        if (openShadow !== false) {
            openShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        $(th_overlayModified.selector_overlay + ".open").each(function (e) {
            th_overlayModified.close($(this).attr('id'), false, true)
        });

        $('#' + overlayId).addClass('open');

        if (openShadow == true) {
            $(th_overlayModified.selector_overlay_shadow).addClass('open');
        }

        if (doCallback == true) {
            $.each(th_overlayModified.callbackOpen, function (k, callback) {
                callback(overlayId);
            });
        }
    },

    close: function (overlayId, closeShadow, doCallback) {

        if (closeShadow !== false) {
            closeShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        if (overlayId) {
            $('#' + overlayId).removeClass('open');
            if ($(th_overlayModified.selector_overlay + '.open').length == 0 && closeShadow) {
                $(th_overlayModified.selector_overlay_shadow).removeClass('open');
            }
        } else {
            $(th_overlayModified.selector_overlay + '.open').removeClass('open');
            if (closeShadow) {
                $(th_overlayModified.selector_overlay_shadow).removeClass('open');
            }
        }

        if (doCallback) {
            $.each(th_overlayModified.callbackClose, function (k, callback) {
                callback(overlayId);
            });
        }
    }
};

th_overlayModified.init();
