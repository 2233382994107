// on s'assure de repasser le body scrollable
th_overlayModified.addCallbackClose(function(){
    $('body').css('overflow', 'visible');
});

th_overlayModified.addCallbackOpen(function(overlay_id){
    if (overlay_id == "overlay-arty"){
        $('body').css('overflow', 'hidden');
        var iframe = $('#overlay-arty iframe');
        if (!iframe.attr('src') || iframe.attr('src') === ''){
            iframe.attr('src',iframe.attr("data-src"));
        }
    }
});

//Ouverture de l'overlay Arty si #arty
if (window.location.hash && window.location.hash === "#arty" && $("#overlay-arty").length >= 0) {
    th_overlayModified.open("overlay-arty");
}
// Ouverture au click sur un lien avec le bon #
$("a").on("click",function(e){
    if ($(this).attr("href").indexOf("#arty") >= 0){
        e.preventDefault();
        //fermeture du menu si jamais il est ouvert
        $('#menu-toggle').prop("checked", false);
        $('.navTrigger-desktop').removeClass('active');
        // fermeture des autres overlays ouverts
        $(".overlay.open").each(function(){
            th_overlayModified.close($(this).attr("id"));
        });
        th_overlayModified.open("overlay-arty");
    }
});