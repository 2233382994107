thBlocs.add('pageevent', function () {
    if ($('.page-top').length > 0) {

        debug('INIT bloc : page top');

        if (!isTabletPortraitOrSmalller()) {
            pageEventCheckImgLoaded();
        }

        $(window).bind('orientationchange', function (event) {
            location.reload(true);
        });

    }
});

function detectCurrentMenuScroll(x_position) {
    var last = null;
    $('.wrapper-top .item').each(function () {
        console.log($(this).offsetLeft());
        if ($(this).offsetLeft() < 0) {
            last = this;
        }
    });
    return last;
}

function parallaxText(x_position) {
    var scroll = -x_position;
    var speed = 0.5;

    $('.parallax-text').each(function () {
        var $this = $(this);
        var $parent = $this.parent();
        var topOffset = $parent.offset().top;
        var height = $parent.outerHeight(true);
        var parallaxSize = (scroll - topOffset) * speed;

        $this.css({
            'transform': scroll >= topOffset ? ('translateX(' + parallaxSize + 'px)' ) : ''
        });
    });
}


function pageEventCheckImgLoaded() {
    var allLoaded = true;
    $('img', '.page-top').each(function () {
        console.log(this);
        if (!this.complete) {
            allLoaded = false;
        }
    });

    if (allLoaded) {
        pageEventInit();
    } else {
        setTimeout(pageEventCheckImgLoaded, 500);
    }
}


function pageEventInit() {
    $('#scroller').css('opacity', 1);
    var nb_item = $('.event').length + 1;

    var width = 700;
    $('#scroller .item').each(function () {
        var imgW = $('figure', this).width();
        var imgH = $('figure', this).height();

        var newImgW = imgW;
        var newImgH = imgH;
        if (document.body.clientHeight < 800 && imgH > 410) {
            newImgW = (imgW / imgH) * 410;
            newImgH = 410;
        }

        $(this).width(newImgW + 400 + 40);
        $('figure', this).width(newImgW).height(newImgH);
        width += $(this).width() + 100;
    });

    $('#scroller,#scroller > div').css('width', width);

    var myScroll = new IScroll('#wrapper', {scrollX: true, scrollY: false, click:true, mouseWheel: true, probeType: 3});


    setTimeout(function () {
        $('.parallax-text').css({'display': 'block', 'opacity': 0}).animate({opacity: 1}, 1000);
    }, 250);

    $('.selecteurs').each(function () {

        var $selecteur = $(this);

        $selecteur.find('ul li:not(.floating)').first().addClass('current');

        var $current = $selecteur.find('.current');
        var $floating = $selecteur.find('.floating.bg');
        var $floatingBar = $selecteur.find('.floating.bar');

        var curWidth = $current.outerWidth() + 2;
        var curLeft = $current.offsetLeft() - 1 - $selecteur.offsetLeft();

        var scrollLeft = 0;

        var $scrollable = $selecteur.find('.scrollable');

        if ($scrollable.length > 0) {

            $scrollable.on('scroll', function () {
                scrollLeft = $(this).scrollLeft();
            });
            if ($selecteur.find('.nav').length > 0) {

                if ($scrollable.get(0).scrollWidth <= $scrollable.width()) {
                    $selecteur.find('.nav').remove();
                } else {
                    $selecteur.find('.nav .prev').on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $scrollable.animate({scrollLeft: (($scrollable.scrollLeft() - ($selecteur.width() - 100)) / 2)}, 300);
                    });
                    $selecteur.find('.nav .next').on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        $scrollable.animate({scrollLeft: (($scrollable.scrollLeft() + ($selecteur.width() - 100)) / 2)}, 300);
                    });
                }
            }

            var scrollableWidth = 0;
            $scrollable.find('ul > *').each(function () {
                scrollableWidth += $(this).outerWidth();
            });

            scrollableWidth += 50;

            $scrollable.find('ul').width(scrollableWidth);

        }


        if (!isTouchDevice() && $(window).width() > thConfig.tabletPaysageBreakpoint) {
            myScroll.on('scroll', function () {
                var x = this.x;
                parallaxText(x);
                var last = detectCurrentMenuScroll(x);
                var getNext = true;
                if (!last) {
                    last = $('.wrapper-top .item').first();
                    getNext = false;
                }
                if (last) {
                    $('.selecteurs .current').removeClass('current');

                    if (getNext) {
                        $c = $('a[href="#' + $(last).next().attr('id') + '"]').parent();
                    } else {
                        $c = $('a[href="#' + $(last).attr('id') + '"]').parent();
                    }

                    $c.addClass('current');

                    var w = $c.outerWidth() + 2;
                    var l = $c.offsetLeft() - $selecteur.offsetLeft() - 1;

                    $c.addClass('hover');
                    $floating.css({left: (l - 1), width: w});
                }
            });
        } else {
            myScroll.on('scroll', function () {

            });
        }


        $floating.hide(0).css({left: (curLeft - 1), width: curWidth}).show(0);
        $floatingBar.hide(0).css({left: (curLeft - 1), right: ($selecteur.width() - (curLeft - 1) + 38)}).show(0);

        $('.selecteurs a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            myScroll.scrollToElement($($(this).attr('href')).get(0), 350, -($('.selecteurs').offsetLeft()), 0);
        });

        $selecteur.find('li:not(.floating)').each(function () {

            var $c = $(this);

            $(this).on('mouseenter', function () {

                var w = $c.outerWidth() + 2;
                var l = $c.offsetLeft() - $selecteur.offsetLeft() - 1 + scrollLeft;

                $c.addClass('hover');
                $selecteur.addClass('disableCurrent');
                $floating.css({left: (l - 1), width: w});
                $floatingBar.css({left: (l - 1), right: ($selecteur.width() - (curLeft - 1) + 38)});

            }).on('mouseleave', function () {

                $current = $selecteur.find('.current');
                curWidth = $current.outerWidth() + 2;
                curLeft = $current.offsetLeft() - $selecteur.offsetLeft() - 1;
                if (curLeft < 1) {
                    curLeft = 1;
                }


                $c.removeClass('hover');
                $selecteur.removeClass('disableCurrent');
                $floating.css({left: (curLeft - 1), width: curWidth});
                $floatingBar.css({left: (curLeft - 1), right: ($selecteur.width() - (curLeft - 1) + 38)});
            }).on('click', function () {
                $selecteur.find('.current').removeClass('current');
                $(this).addClass('current');
            });

        });

    });


    /*

     $('.selecteurs').each(function(){


     $('.floating',this).next().addClass('current');

     var $selecteur = $(this);

     var $current = $selecteur.find('.current');
     var $floating = $selecteur.find('.floating.bg');

     var curWidth = $current.outerWidth()+2;
     var curLeft = $current.offsetLeft()-1-$selecteur.offsetLeft();

     $floating.css({left:(curLeft-1),width:curWidth});


     if($selecteur.find('.nav').length > 0){

     $selecteur.find('.nav .icon-prev').on('click',function(e){
     e.preventDefault();
     e.stopPropagation();
     $selecteur.find('.nav').animate({scrollLeft:($selecteur.find('.nav').scrollLeft()-($selecteur.width()-100))},300);
     });
     $selecteur.find('.nav .icon-next').on('click',function(e){
     e.preventDefault();
     e.stopPropagation();
     $selecteur.find('.nav').animate({scrollLeft:($selecteur.find('.nav').scrollLeft()+($selecteur.width()-100))},300);
     });
     }




     if(!isTouchDevice() && $(window).width() > thConfig.tabletPaysageBreakpoint) {
     myScroll.on('scroll', function(){
     var x = this.x;
     parallaxText(x);
     var last = detectCurrentMenuScroll(x);
     var getNext = true;
     if(!last){
     last = $('.wrapper-top .item').first();
     getNext = false;
     }
     if(last){
     $('.selecteurs .current').removeClass('current');

     if(getNext){
     $c = $('a[href="#'+$(last).next().attr('id')+'"]').parent();
     }else{
     $c = $('a[href="#'+$(last).attr('id')+'"]').parent();
     }

     $c.addClass('current');

     var w = $c.outerWidth()+2;
     var l = $c.offsetLeft()-$selecteur.offsetLeft()-1;

     $c.addClass('hover');
     $selecteur.addClass('disableCurrent');
     $floating.css({left:(l-1),width:w});
     }
     });
     }else{
     myScroll.on('scroll', function(){

     });
     }



     $('.selecteurs a[href^="#"]').on('click',function(e){
     e.preventDefault();
     myScroll.scrollToElement($($(this).attr('href')).get(0),350,-($('.selecteurs').offsetLeft()),0);
     });


     $selecteur.find('li:not(.floating)').each(function(){

     var $c = $(this);

     $(this).on('mouseenter',function(){

     var w = $c.outerWidth()+2;
     var l = $c.offsetLeft()-$selecteur.offsetLeft()-1;

     $c.addClass('hover');
     $selecteur.addClass('disableCurrent');
     $floating.css({left:(l-1),width:w});

     }).on('mouseleave',function(){

     $current = $selecteur.find('.current');
     curWidth = $current.outerWidth()+2;
     curLeft = $current.offsetLeft()-$selecteur.offsetLeft()-1;

     $c.removeClass('hover');
     $selecteur.removeClass('disableCurrent');
     $floating.css({left:(curLeft-1),width:curWidth});
     }).on('click',function(){
     $selecteur.find('.current').removeClass('current');
     $(this).addClass('current');
     });

     });

     });

     */

}