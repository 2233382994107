th_maps.addThemes("maplibre", {
    attribution: "European Environment Agency (EEA) | <a href=\"https://www.openstreetmap.org/copyright/fr\" target=\"_blank\" rel=\"nofollow noopener\">© OpenMapTiles © OpenStreetMap contributors © Loopi</a>&nbsp;&nbsp;",
    style: "https://tiles.loopi-velo.fr/styles/loopi-v3/style.json",
});

function callbackMiniMapCards(macarte, options, marker, el) {
    var groupBounds = null;
    var markerlist = [];
    var timeoutResetToBounds = null;
    var defaultZoom = null;
    let loadedKMLs = [];
    let isHoveringCard = false;

    $(macarte._container).parent().find('.listing-map.cards-mini [data-vignettelei]').each(function () {
        var _self = this;
        var marker = th_maps.createMarker(macarte, {
            lat: (this.dataset.lat.replace(',', '.')) * 1,
            lng: (this.dataset.lng.replace(',', '.')) * 1
        }, 'default');
        markerlist.push(marker);

        const lei_id = $(this).data('vignettelei');

        relationMapMobile.markers[lei_id] = marker;

        if (document.body.clientWidth > 922) {

            let width = 250;
            if ($('body').hasClass('site_velo') && $(this).hasClass('bloc-card-lei-itineraire')) {
                width = 460;
            }
            var infowindow = th_maps.createInfoWindow($(this)[0].outerHTML, marker, width);
            var kml = $(this).attr('data-kml');
            var kmlLayer = false;

            $(this).on('mouseenter', function () {
                clearTimeout(timeoutResetToBounds);
                marker.setIcon(th_maps.markersIcons['default-hover']);
                macarte.flyTo(marker.getLatLng(), defaultZoom + 1);

                if (kml) {

                    isHoveringCard = true
                    if (loadedKMLs[lei_id] === undefined) {
                        loadedKMLs[lei_id] = omnivore.kml(kml);

                        loadedKMLs[lei_id]
                            .on('ready', function (e) {
                                var highlightStyle = {
                                    fillColor: "00FFFFFF",
                                    weight: 3,
                                    opacity: 1,
                                    color: "#D74537",
                                    fillOpacity: 0.0
                                };

                                this.setStyle(highlightStyle);
                                macarte.flyToBounds(loadedKMLs[lei_id].getBounds(), {padding: [100, 25]});
                            })
                            .on('error', function () {
                                loadedKMLs[lei_id] = undefined
                            })
                            .addTo(macarte)

                    } else {
                        loadedKMLs[lei_id].addTo(macarte)
                        macarte.flyToBounds(loadedKMLs[lei_id].getBounds(), {padding: [100, 25]});
                    }
                }
            }).on('mouseleave', function () {
                isHoveringCard = false;
                marker.setIcon(th_maps.markersIcons['default']);
                macarte.removeLayer(loadedKMLs[lei_id]);

                timeoutResetToBounds = setTimeout(function () {
                    if (groupBounds) {
                        if (isHoveringCard === false) {
                            macarte.flyToBounds(groupBounds.getBounds());
                        }
                    }
                }, 300);
            });
        } else {
            marker.addEventListener('click', function () {
                var ol = $(_self).offsetLeft();
                var olWrapper = 27;
                var sl = $(el).parent().find('.listing-map.cards-mini').scrollLeft();
                $(el).parent().find('.listing-map.cards-mini').animate({scrollLeft: (sl + ol - olWrapper)}, 300);
            })
        }

    });

    if (document.body.clientWidth < 922) {
        $(el).parent().find('.listing-map.cards-mini').on('scroll', function () {
            relationMapMobile.handleScrollMobileCards(this, function (lei_id) {
                $('[data-vignettelei].active', $(el).parent()).removeClass('active');
                $('[data-vignettelei="' + lei_id + '"]', $(el).parent()).addClass('active');
            });
        });

        relationMapMobile.handleScrollMobileCards($(el).parent().find('.listing-map.cards-mini').get(0), function (lei_id) {
            $('[data-vignettelei].active', $(el).parent()).removeClass('active');
            $('[data-vignettelei="' + lei_id + '"]', $(el).parent()).addClass('active');
        });
    }

    groupBounds = new L.featureGroup(markerlist);
    macarte.fitBounds(groupBounds.getBounds());
    defaultZoom = macarte.getZoom();

    relationMapMobile.map = macarte;
}

function callbackmapsitineraire(macarte) {
    setTimeout(function () {
        macarte.invalidateSize();
    }, 500);
}


// PAGE SINGLE Scrolly-telling
function callbackMapPageSingleScrolly(macarte) {
    macarte.zoomControl.remove();
    macarte.doubleClickZoom.disable();

    std.init(macarte);
}