thBlocs.add('incontournable',function(){

    $('.bloc-incontournables').each(function(){
        debug('INIT bloc : incontournable', this);
        if(!isMobileOrSmalller()){
            $('.listing-item',this).slick({
                infinite: false,
                variableWidth: true,
                dots: true,
                rows:1,
                centerMode: false,
                slidesToShow:3,
                draggable: true,
                appendDots: $('.slider-dots',this)[0],
                nextArrow:$('.slider-nav .next',this)[0],
                prevArrow:$('.slider-nav .prev',this)[0],
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            draggable: true,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 920,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            });
        }else{
            $('.listing-item',this).slick({
                infinite: true,
                variableWidth: false,
                rows: 2,
                slidesToShow:1,
                slidesPerRow:2,
                dots: true,
                appendDots: $('.slider-dots',this)[0],
                nextArrow:$('.slider-nav .next',this)[0],
                prevArrow:$('.slider-nav .prev',this)[0]
            });
        }
    });
});