var thPWA = {
  origin: document.location.origin,
  _regexOrigin: null,
  _animEndEventName: 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend',

  initHref: null,

  callbacks: {},

  isInit: false,
  enabled: false,

  prevUrl: null,
  prevUrlNoHash: null,
  prevState: null,


  init: function(){
    thPWA.isInit = true;

    debug('INIT : PWA');

    thPWA.initHref = document.location.href;
    thPWA.prevUrl = document.location.href;
    thPWA.prevUrlNoHash = current_href();

    thPWA._regexOrigin = new RegExp('^'+thPWA.origin);


    thPWA.enablePop();

    thPWA.parseContent();

  },

  enablePop: function(){
    if(!thPWA.isInit){ return false; }
    thPWA.enabled = 1;
    window.addEventListener('popstate',thPWA.handlePopState);
  },

  disablePop: function(){
    if(!thPWA.isInit){ return false; }
    thPWA.enabled = 0;
    window.removeEventListener('popstate',thPWA.handlePopState);
  },

  tempDisable: function(){
    if(!thPWA.isInit){ return false; }
    if(thPWA.enabled){
      thPWA.disablePop();
      setTimeout(function(){thPWA.enablePop();},500);
    }
  },

  thLinkall: function(destinationURL){
    if(thPWA.isInit && destinationURL.match(thPWA._regexOrigin)){

      thPWA.prevState = {url:destinationURL};
      thPWA.prevUrlNoHash = current_href();
      thPWA.prevUrl = destinationURL;
      history.pushState({url:destinationURL}, null, destinationURL);
      thPWA.loadPage(destinationURL);
    }else{
      document.location.href = destinationURL;
    }
  },

  parseContent: function(el){

    if(!el){
      el = document;
    }
    $('a:not(.pwa-parsed):not(.zoombox)',el).each(function(){

      var destinationURL = this.getAttribute('href');

      if(destinationURL.match(thPWA._regexOrigin)){

        this.addEventListener('click',function(e){
          e.preventDefault();
          e.stopPropagation();

          console.log('Click sur : '+destinationURL);

          thPWA.prevState = {url:destinationURL};
          thPWA.prevUrlNoHash = current_href();
          thPWA.prevUrl = destinationURL;
          history.pushState({url:destinationURL}, null, destinationURL);
          thPWA.loadPage(destinationURL);
        });

      }

      $(this).addClass('pwa-parsed');

    });

    th_expand.parse(el);

    if(tarteaucitron.launch.vimeo == true) {
      tarteaucitron.launch.vimeo = false;
      tarteaucitron.launch.youtube = false;
      tarteaucitron.launch.dailymotion = false;
      tarteaucitron.init();
    }

    if(typeof frmRecaptcha === 'function'){
      try {
          frmRecaptcha();
      }catch(e){}
    }

    $.each(thPWA.callbacks,function(name,callback){
      callback();
    });
  },

  loadPage: function(url){

    var menuToggle = document.getElementById('menu-toggle');
    if(menuToggle.checked == true){
      menuToggle.checked = false;
      setTimeout(resetMenuFormItems,500);
    }

    var st = $('body,html').scrollTop();
    $('#pwa-content').addClass('pt-page pt-page-current').scrollTop(st);
    $('#header').attr('class','menu-no-header');
    $('body,html').scrollTop(1); // ATTENTION : important, a 1 puis passe a 0 par la suite, sinon bug de rendu navigateur

    $('#pwa-wrapper > div').first().addClass('pt-page-moveToLeft').on( thPWA._animEndEventName, function() {
      $(this).off( thPWA._animEndEventName ).remove();
    } );

    $.get(url,function(html){
      $('body,html').scrollTop(0); // ATTENTION : important, passe de 1 a 0, sinon bug de rendu navigateur

      document.title = $(html).filter('title').text();

      // affichage ou retrait du chevron history back sur ios
      var historyBackLink = $(html).filter('header').find('#history-back').html();
      if(historyBackLink && historyBackLink.length > 0 && $('#history-back').length === 0){
        $('header#header .container').prepend('<a href="javascript:history.back();" id="history-back">'+historyBackLink+'</a>')
      }
      if((!historyBackLink || (historyBackLink && historyBackLink.length === 0)) && $('#history-back').length > 0){
        $('#history-back').remove();
      }
      // end

      // class du menu
      if($(html).filter('#header').find('> .menu-pro').length > 0){
        if($('header#header > .menu-pro').length == 0){
          $('header#header > .container').after($(html).filter('#header').find('> .menu-pro'));

          $('header#header > .menu-pro').css('opacity',0).animate({opacity:1},400);
        }
      }else{
        $('header#header > .menu-pro').animate({opacity:0},400,function(){$(this).remove();});
      }

      // menu press
      var headerClass = $(html).filter('header#header').attr('class');
      if(typeof headerClass != 'undefined'){
        $('#header').attr('class',$(html).filter('#header').attr('class'));
      }else{
        $('#header').attr('class','');
      }


      // supprime les overlays inutiles
      $('#overlays > *').each(function(){
        var overlayName = $(this).attr('id');
        if(overlayName && overlayName != 'overlay-share' && overlayName != 'overlay-map'){
          $('#'+overlayName).remove();
        }
      });
      // ajoute les nouveau overlays
      $(html).filter('#overlays').find('> *').each(function(){
        var overlayName = $(this).attr('id');
        if(overlayName && overlayName != 'overlay-share' && overlayName != 'overlay-map'){
          $('#overlays').append(this);
        }
      });

      if($('#header').hasClass('menu-no-header')){
        $('.logo-alsace').addClass('menu-no-header');
      }else{
        $('.logo-alsace').removeClass('menu-no-header');
      }


      var div = document.createElement('div');
      div.setAttribute('class','pt-page');
      div.setAttribute('id','pwa-content');
      console.log($(html).filter('div#pwa-wrapper'));
      div.innerHTML = $(html).filter('div#pwa-wrapper').find('> div').html();
      $('#pwa-wrapper').append(div);

      setTimeout(function(){

        $(div).last().addClass('pt-page-current pt-page-moveFromRight').on( thPWA._animEndEventName, function() {
          $(this).off( thPWA._animEndEventName ).removeClass('pt-page-moveFromRight pt-page pt-page-current');
          while($('#pwa-wrapper > div').length > 1){
            $('#pwa-wrapper > div').first().remove();
          }
          setTimeout(function(){ thPWA.parseContent(); },250);
        });

      },50);


    });

  },

  handlePopState: function(e){

    console.log(document.location.href);
    console.log(e);
    console.log(e.state);

    algoliaFacette.preventHashchange = true;
    setTimeout(function(){
      algoliaFacette.preventHashchange = false;
    },250);


    var state = e.state;

    if (state == null) {
      if(thPWA.initHref != document.location.href) {
        window.history.back();
      }else{
        thPWA.loadPage(document.location.href);
      }
    } else {
      if(state.is_hashchange && thPWA.prevState && state.url == thPWA.prevState.url){

        console.log('prevurl',thPWA.prevUrl);
        console.log('prevurl no hash',thPWA.prevUrlNoHash);
        console.log('prevstate',thPWA.prevState);
        console.log('state',state);

        algoliaFacette.updateByHash(state.is_hashchange);


      }else if(!thPWA.prevState || (state.url != thPWA.prevState.url)){
        thPWA.loadPage(state.url);
      }else if(thPWA.prevState && state.url == thPWA.prevState.url && thPWA.prevState.is_hashchange){
        algoliaFacette.updateByHash('');
      }
    }

    thPWA.prevUrl = document.location.href;
    thPWA.prevUrlNoHash = current_href();
    thPWA.prevState = e.state;

  }
};

if(isTabletPortraitOrSmalller()){
  thPWA.init();
}else{
  var oldST = $('#pwa-content').scrollTop();
  $('#pwa-content').removeClass('pt-page pt-page-current');
  $(document).scrollTop(oldST);

}
