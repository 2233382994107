thBlocs.add('pageItineraire',function(){

    if($('.wrapper-content-itineraire').length > 0){

        if(!isTabletPortraitOrSmalller()){
            th_maps.initSingleMap($('.wrapper-content-itineraire .maps').get(0));
        }

        $('.nav-step ul li a').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            $('body,html').animate({scrollTop:($($(this).attr('href')).offsetTop()-125)},300);
        });

        $(document).on('scroll',function(){
            var st = $(this).scrollTop();
            var last = null
            $('.step h2.title').each(function(){
                if($(this).offsetTop()-300 < st){
                    last = $(this).attr('id');
                }
            });
            $('.nav-step a.active').removeClass('active');

            if(last){
                $('a[href="#'+last+'"]').addClass('active');
            }
        });
    }

});