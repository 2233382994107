thBlocs.add('mapsvg',function(){

    if($('.map-svg').length > 0){
        debug('INIT bloc : map svg');

        $('.map-svg .wrapper-points > a').on('click',mapSVG_switchCity);
        $('.map-svg svg > g > g').on('click',mapSVG_switchZone);
    }

});

function mapSVG_switchZone(e){
    e.preventDefault();
    e.stopPropagation();
    $('.map-svg svg > g > g.active').removeClass('active');

    $(this).addClass('active');
    var html = $('.map-svg .city-data [data-zone="'+$(this).attr('id').replace('ZONE','')+'"]').html();
    $('.map-svg > .wrapper-txt').addClass('show-out');

    setTimeout(function(){
        $('.map-svg > .wrapper-txt').addClass('hidedata').removeClass('show-out');
        $('.map-svg > .wrapper-txt').html(html);
        setTimeout(function(){
            $('.map-svg > .wrapper-txt').removeClass('hidedata');
        },100);
    },400);
}

function mapSVG_switchCity(e){
    e.preventDefault();
    e.stopPropagation();

    $('.map-svg svg > g > g.active').removeClass('active');

    //$(this).attr('href').replace('#','')

    $('.map-svg .wrapper-points a.show-pulse').removeClass('show-pulse');
    $(this).addClass('show-pulse');

    setTimeout(function(){
        $('.map-svg .wrapper-points a.show-pulse').removeClass('show-pulse');

    },600);

    var html = $('.map-svg .city-data [data-id="'+$(this).attr('href')+'"]').html();
    $('.map-svg > .wrapper-txt').addClass('show-out');

    setTimeout(function(){
        $('.map-svg > .wrapper-txt').addClass('hidedata').removeClass('show-out');
        $('.map-svg > .wrapper-txt').html(html);
        setTimeout(function(){
            $('.map-svg > .wrapper-txt').removeClass('hidedata');
        },100);
    },400);
}
