var scrolly = {
    ticking : false,
    arrayIndexEtapes:[],
    arrayEtapesPxl: [],
    totalEtapesPxl : 0,
    clientHeight : document.body.clientHeight,
    scrollPrecedent : 0,
    latlngsTotal: [],
    coordsTrace : [],
    indexCanvasPointTop: [],
    map : null,
    previousEtape: 0,

    // PARAM :

    // WARNING: rajoute le margin d'une etape, outerHeight ne semble pas le calc
    marginEtape : 40,
    idEtapes : 'etape-',
    layerWeight : 6,
    //todo :param layer
    layerColor : 'red',
    gpxMarkerSite: 'site_velo',


    init: function(){
        var color = $('.maps').attr('data-kml-color');
        if(color){
            scrolly.layerColor = color;
        }


        var site = $('.maps').attr('data-kml-site');
        if (site){
            scrolly.gpxMarkerSite = site;
        }

        scrolly.watchScroll();
    },

    initScroll : function(route, etapes, map){
        //route => array([lat, lng], [lat, lng], ...)
        // routeComplete sous la bonne forme

        scrolly.latlngsTotal = [route];
        scrolly.map = map;

        //Generation du array des index Etape => array([0, 10], [11, 32], [33, 60])
        scrolly.arrayIndexEtapes = scrolly.totalArrayEtapes(scrolly.latlngsTotal, etapes);

        //resize en cas de changement de taille/format d'écran
        $(window).resize(function() {
            //Generation du array des pixel Etapes + total de toute les etapes
            scrolly.totalPxlEtapes = 0;
            for(i = 0; i < etapes.length; i++){
                scrolly.arrayEtapesPxl[i] = $("#etape-"+i).outerHeight()+scrolly.marginEtape;
                scrolly.totalPxlEtapes = scrolly.totalPxlEtapes + $("#etape-"+i).outerHeight()+scrolly.marginEtape;
            }
        }).resize()

        stpi.pushIconeBSP(scrolly.map, 0);
    },

    totalArrayEtapes: function(array, etapes){
        arrayCibles = [];

        for(i = 0; i < etapes.length; i++){
            arrayCibles[i] = [etapes[i]["lat"], etapes[i]["lng"]]
        }

        pointsLesPlusProches = scrolly.calcPointsLesPlusProches(array[0], arrayCibles);

        resp = [];
        for(i = 0; i < pointsLesPlusProches.length; i++){
            if(i != pointsLesPlusProches.length-1){
                resp[i] = [pointsLesPlusProches[i][0], pointsLesPlusProches[i+1][0]-1]
            } else {
                // derniere etape
                resp[i] = [pointsLesPlusProches[i][0], array[0].length]
            }
        }

        return resp
    },

    calcPointsLesPlusProches: function(array, arrayCibles){
        // lat point - lat marker + lng point - lng marker
        // plus le chiffre est bas plus on est proche, celui qui est le plus bas est le plus proche du tracer

        notes = [];
        pointsLesPlusProches = [];
        for(j = 0; j < arrayCibles.length; j++){
            notes[j] = 1000;
        }
        for(i = 0; i < array.length; i++){

            for(k = 0; k < j; k++){
                lat = Math.abs(array[i]['lat'] - arrayCibles[k][0])
                lng = Math.abs(array[i]['lng'] - arrayCibles[k][1])
                total = lat + lng;

                if(total < notes[k]){
                    notes[k] = total
                    pointsLesPlusProches[k] = [i, array[i]];
                }
            }
        }

        return pointsLesPlusProches;
    },

    getIterationsEtapes : function (scroll){
        var newIterationsEtapes = scrolly.getIterationEtape(scroll);
        var oldIterationsEtapes = scrolly.getIterationEtape(scrolly.scrollPrecedent);

        if(!newIterationsEtapes || !oldIterationsEtapes){
            return null;
        }

        return [newIterationsEtapes, oldIterationsEtapes];
    },

    getIterationEtape: function (scroll){
        // d'abord on trouve a quel etape on est


        var etapeScroll = scrolly.getEtapeScroll(scroll);
        if(!etapeScroll){
            // carte pas encore en place
            return null;
        }
        var etape = etapeScroll[0];
        scroll = etapeScroll[1];

        //on a l'etape, on calcul le % dans l'étape
        var pourcent = scroll / scrolly.arrayEtapesPxl[etape] * 100;

        if(pourcent > 100) {
            //assurance dépassement
            pourcent = 100;
        }

        var nbrIterationsDansLetape = scrolly.arrayIndexEtapes[etape][1] - scrolly.arrayIndexEtapes[etape][0];
        var newIterationDansLetape = Math.trunc( nbrIterationsDansLetape  / 100 * pourcent );

        var iterationFinal = newIterationDansLetape+scrolly.arrayIndexEtapes[etape][0];

        //assurance, en cas de scroll trop rapide ou de changement de taille de page
        if(etape < 0){
            etape = 0
        }
        if(iterationFinal < 0){
            iterationFinal = 0
        }

        return [etape, iterationFinal];
    },

    getEtapeScroll: function (scroll){
        // scroll = pxl scroll depuis etape-0
        // on retire de scroll chaque etape, si ca fais moins que 0
        // on est donc dans cet etape d'un nombre de pxl = scroll restant

        for(i = 0; i < scrolly.arrayEtapesPxl.length; i++){
            if( scroll - scrolly.arrayEtapesPxl[i] <= 0){
                //on est dans la bonne étape en cour
                return [i, scroll];
            } else {
                scroll = scroll - scrolly.arrayEtapesPxl[i];
            }
        }

        //Renvois le max
        return [scrolly.arrayEtapesPxl.length-1, scrolly.arrayEtapesPxl[scrolly.arrayEtapesPxl.length-1]];
    },

    stRemoveLayer: function (indexToRemove){
        if (arrayObj[indexToRemove][1].editing && arrayObj[indexToRemove][1].editing._marker){
            indexToRemove = indexToRemove - 1;
            scrolly.stRemoveLayer(indexToRemove);
        }else if(arrayObj[indexToRemove][1].editing){
            scrolly.map.removeLayer(arrayObj[indexToRemove][1]);
        }

    },

    changeLayer : function(array, iteration){
        newArray = array.slice(0, iteration);
        arrayObj = Object.entries(scrolly.map._layers);
        scrolly.stRemoveLayer(arrayObj.length-1);
        path = L.polyline([newArray], {color: scrolly.layerColor, weight: scrolly.layerWeight});
        scrolly.map.addLayer(path);
    },

    watchScroll : function(){
        if ($(".itinerire-lei.--not-lei").length > 0){
            $(window).on('scroll', function(e) {

                var note = document.getElementById(scrolly.idEtapes +'0');
                if(note) {
                    //position par rapport à l'etape 0
                    screenPosition = note.getBoundingClientRect();
                    if (!scrolly.ticking) {
                        window.requestAnimationFrame(function() {
                            scrolly.scrollAction(screenPosition);
                            scrolly.ticking = false;
                        });

                        scrolly.ticking = true;
                    }
                }

            });
        }
    },

    scrollAction : function(screenPosition) {

        //on retire la moitié de l'écran du client pour cibler au centre
        if(screenPosition.y - (scrolly.clientHeight/2) <= 0){

            // le scroll en cour
            scrollEnCour = Math.abs(screenPosition.y - (scrolly.clientHeight/2) )
            if(scrollEnCour > scrolly.totalPxlEtapes){
                scrollEnCour = scrolly.totalPxlEtapes+10000;
                // aucune exécution après le dépassement du scroll max
                //return
            }
            // array pour défnir les différentes étapes à gérer
            // iterationsEtapes[ int Etape ciblé, iteration de l'etape ciblé], [int Etape précédente, iteration precedente]
            iterationsEtapes = scrolly.getIterationsEtapes(scrollEnCour);
            if(!iterationsEtapes){
                //la carte n'est pas encore initialisé
                return
            }

            if(iterationsEtapes[0][0] === iterationsEtapes[1][0] && iterationsEtapes[0][1] === iterationsEtapes[1][1]){
                //pas de modif entre la version précédente et la nouvelle version
                return
            }

            scrolly.changeLayer(scrolly.latlngsTotal[0], iterationsEtapes[0][1]);

            //on met les infos à jour
            scrolly.scrollPrecedent = scrollEnCour;

            // deplacement sur la carte
            if(iterationsEtapes[0][1] == scrolly.latlngsTotal[0].length){
                //dernier point
                scrolly.map.flyToBounds(scrolly.latlngsTotal[0]);
            } else {
                //code pourle flyToBounds
                // arrayBound = scrolly.latlngsTotal[0].slice(scrolly.arrayEtapesPxl[iterationsEtapes[0][0]][0], scrolly.arrayEtapesPxl[iterationsEtapes[0][0]][1])
                // std.map.flyToBounds(arrayBound);
                scrolly.map.setZoomAround(scrolly.latlngsTotal[0][iterationsEtapes[0][1]], 17, true);
                scrolly.map.panTo(scrolly.latlngsTotal[0][iterationsEtapes[0][1]]);
            }
            if(iterationsEtapes[0][1] == 0){
                scrolly.map.fitBounds(scrolly.latlngsTotal[0]);
            }

            //marker de françois
            // Mise en avant des marker des étapes au changement des étapes

            if (iterationsEtapes[0][0] !== iterationsEtapes[1][0]){

                stpi.pushIconeBSP(scrolly.map, iterationsEtapes[0][0]);

                $.each(std.markerEtape,function(k,marker){
                    var markerIndex = iterationsEtapes[0][0] - 1;
                    var trueKey = k+1;
                    var trueIndex = markerIndex+1;

                    if (k === markerIndex){
                        marker.setIcon(th_maps.markersIcons['scrolly-'+scrolly.gpxMarkerSite+'-current']);
                    }else{
                        if (k < markerIndex){
                            marker.setIcon(th_maps.markersIcons['scrolly-'+scrolly.gpxMarkerSite+'-passed']);
                        }else{
                            marker.setIcon(th_maps.markersIcons['scrolly-'+scrolly.gpxMarkerSite+'-'+trueKey]);
                        }
                    }
                });
            }
        } else {
            // Aucune etape n'est affiché à l'écran
            if(scrolly.scrollPrecedent > 0){
                //Tout n'as pas été entièrement réinitialisé

                // on remet le layer polyfile comme il était au début
                arrayObj = Object.entries(scrolly.map._layers);
                scrolly.stRemoveLayer(arrayObj.length-1);

                scrolly.map.fitBounds(scrolly.latlngsTotal[0]);
                //std.map.setZoomAround(std.latlngsTotal[0][iterationsEtapes[0][1]], 20, 1);
                scrolly.scrollPrecedent = 0;
            }
        }

    },

}

scrolly.init()