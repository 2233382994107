// OVERLAY SCROLLY V2
async function callbackMapScrollyFull(map) {
    await scrollyV2.init(map);
    map.zoomControl.remove();
    map.dragging.disable();
    if (isTouchDevice()) {
        map.doubleClickZoom.disable();
    }
}

/**
 *
 *
 *  01/09/2023
 *  @TODO mise en prod, le tout est complètement fonctionnel en état et validé par Steph/Seb/Jo (les derniers commits concernent leurs retours)
 *  quelques infos néanmoins :
 *
 *  - le fichier scrolly V2 a été créé pour éviter de créer un monstre comme c'était en train d'arriver avec le scrolly classique,
 *     j'ai donc dupliqué le fichier de base et travaillé dessus il y a eu pas mal de modifs
 *     MAIS
 *     je ne sais pas si les modifs qui avaient été faites avant sur le fichier scrolly.js ont un impact sur le scrolly originel,
 *     ce ne serait pas déconnant de reprendre le fichier de prod, peut-être, si jamais il devait y avoir des soucis
 *
 *  - les derniers commits ont été un peu crades, il fallait essayer de finir à temps (désolé)
 *  - s'il devait y avoir des points à améliorer :
 *     -> déplacer les différents sélecteurs CSS utilisés dans le fichier vers l'init
 *     -> utiliser une version à jour de leaflet pour des performances (actuellement version datant de 2017,
 *        j'ai testé la version 2023 et les différents petits glitchs qu'on peut avoir tendent à disparaître, également sur mobile)
 *        Mais ça veut dire revoir toutes les maps du site ainsi que th_maps (il faudrait peut-être s'en débarasser d'ailleurs)
 *        car certaines choses ne fonctionnent plus de la même manière, le CSS est différent, etc...
 *     -> voir les quelques "@todo" qui restent dans le fichier
 *     -> et pour pinailler : séparer en plusieurs classes les différents composants,
 *        qui auraient toutes leurs propres fonctions private/publiques auxquelles les autres classes auraient accès ou non
 *        + avoir tous les fichiers regroupés au même endroit pour mutualiser et réutiliser sur d'autres sites ?
 *
 *
 */


let scrollyV2 = {
    stepsContentContainer: ".js-content-etapes",
    stepSectionSelector: ".js-section",
    sliderClass: ".js-bloc-relation-scrolly",
    cardStep: ".js-card-step",
    defaultBtn: "default",
    selectedBtn: "hover",
    map: null,
    markers: [],
    popUps: [],
    lastOpenedPopup: null,
    stepsCoordinates: [],
    markersSlider: [],
    coordsSlider: [],
    fullTraceLayer: null,
    traceLayer: null,
    lastOnMarker: null,
    layerColor: "#D74537",
    layerWeight: 6,
    coordsTrace: [],
    overlay: null,
    currentTraceIndex: 0,
    displayedRelationMarkersGroup: null,

    init: async function (map) {
        if ($(".js-scrolly-full").length > 0) {
            scrollyV2.map = map;

            await scrollyV2.addGpxFullTrace()
            scrollyV2.initMarkers();
            scrollyV2.initOverlay()

            const stepsContentPositions = scrollyV2.getStepsContentPositions();
            const traceSections = scrollyV2.getTraceStepsIntersections();
            const stepsContentContainer = document.querySelector(scrollyV2.stepsContentContainer);

            // init trace on page load if scroll > 0
            scrollyV2.handleTraceFilling(
                stepsContentPositions,
                traceSections,
                stepsContentContainer
            )

            window.addEventListener("scroll", function () {
                scrollyV2.handleTraceFilling(
                    stepsContentPositions,
                    traceSections,
                    stepsContentContainer
                )
            });

            scrollyV2.observeOpenCloseOverlayButtons()
            scrollyV2.handleStickyAnchorsClick()
        }
    },


    initMarkers: function () {
        // Ajout une modal sur les points des sections
        $(scrollyV2.cardStep).each(function (id, element) {
            const stepId = Number(element.getAttribute('data-step'));
            const coordinates = {
                lat: Number(element.getAttribute('data-lat')),
                lng: Number(element.getAttribute('data-lng'))
            };

            scrollyV2.stepsCoordinates[stepId] = coordinates;
            scrollyV2.createMarker(stepId, coordinates);
            // @todo utiliser un template plutôt que l'élément
            scrollyV2.addCardStep(stepId, coordinates, element);
        });

        scrollyV2.setCurrentMarkerIcon(0)
    },

    createMarker: function (id, coordinates) {
        //on ne rajoute pas les points mal configuré
        if (coordinates["lat"] === 0 || coordinates["lng"] === 0) {
            return null;
        }

        scrollyV2.markers[id] = L.marker(
            [coordinates.lat, coordinates.lng],
            {icon: th_maps.markersIcons[scrollyV2.defaultBtn]}
        ).addTo(scrollyV2.map);

        return scrollyV2.markers[id];
    },

    goToStep: function (stepID) {
        document.querySelector('.js-section-content [data-step="'+ stepID +'"]').scrollIntoView({ behavior: "smooth", inline: "start" })
    },

    openPopup(id) {
        if(window.innerWidth > 980) {
            scrollyV2.popUps[id].addTo(scrollyV2.map)
        } else {
            const cardContent = document.querySelector('.js-content-etapes .js-card-step[data-step="'+ id +'"]').innerHTML
            document.querySelector('.js-step-popup-mobile .js-step-popup-mobile-content').innerHTML = cardContent
            let stepPopup = document.querySelector('.js-step-popup-mobile')
                stepPopup.classList.add('open')
                stepPopup.querySelector('.js-btn-open-overlay').setAttribute('data-step', id)
        }
        scrollyV2.lastOpenedPopup = id;
    },

    closePopup(id) {
        if(id === null) {
            return false;
        }
        if(window.innerWidth > 980) {
            scrollyV2.popUps[id].remove();
        } else {
           document.querySelector('.js-step-popup-mobile').classList.remove('open')
        }
    },

    setCurrentMarkerIcon: function (targetId) {
        scrollyV2.markers.forEach((marker, id) => {
            scrollyV2.markers[id].setIcon(th_maps.markersIcons[scrollyV2.defaultBtn]);
        })

        scrollyV2.markers[targetId].setIcon(th_maps.markersIcons[scrollyV2.selectedBtn]);
    },

    observeOpenCloseOverlayButtons: function () {

        // opening observer
        document.body.addEventListener('click', event => {
            if(event.target.classList.contains('js-btn-open-overlay')) {

                scrollyV2.closePopup(event.target.dataset.step)
                scrollyV2.hideStickyAnchors()
                scrollyV2.openOverlay(event.target.dataset.step)
                if(window.innerWidth > 980) {
                    scrollyV2.map.panBy([scrollyV2.overlay.offsetWidth / 2, 0])
                } else {
                    scrollyV2.map.panBy([0, scrollyV2.overlay.offsetHeight / 2])
                }
            }
        })

        // closing observer
        document.querySelector('.js-close-step-overlay').addEventListener("click", function () {
            scrollyV2.removeRelationMarkersGroup()
            scrollyV2.openPopup(scrollyV2.lastOpenedPopup)
            scrollyV2.showStickyAnchors()
            scrollyV2.closeOverlay()
            if(window.innerWidth > 980) {
                scrollyV2.map.panBy([scrollyV2.overlay.offsetWidth / -2, 0])
            } else {
                scrollyV2.map.panBy([0, scrollyV2.overlay.offsetHeight / -2])
            }
        })
    },


    // modal card pour les step
    addCardStep: function (id, coordinates, element) {
        let newElcardStep = $(element);
        newElcardStep.find("img").attr('src', newElcardStep.find("img").attr('data-src')).removeClass("lazy");
        const cardStep_html = newElcardStep.get(0).outerHTML;

        const icon = L.divIcon({
            className: 'icon',
            html: "<div></div>",
            iconSize: [30, 30],
            iconAnchor: [20, 20]
        });


        if (coordinates["lat"] !== 0 && coordinates["lng"] !== 0) {
            if (scrollyV2.coordsSlider[id] === undefined) {
                scrollyV2.coordsSlider[id] = newElcardStep;
                scrollyV2.markersSlider[id] = th_maps.createMarker(scrollyV2.map, coordinates, icon, null).on('click', () => {scrollyV2.goToStep(id)});
                scrollyV2.popUps[id] = scrollyV2.createPopup(cardStep_html, scrollyV2.markersSlider[id], 240, id);
            }
        }
    },

    createPopup: function (content, marker, width, stepID) {
        if (!width) {
            width = 240;
        }

        const infoWindow = L.popup({
            className: 'custom-popup-thuria',
            maxWidth: width,
            minWidth: width,
            closeOnClick: false
        })
            .setContent(content)
            .setLatLng(marker.getLatLng())

        marker.addEventListener('click', function () {
            scrollyV2.openPopup(stepID)
        })

        return infoWindow;
    },

    addGpxFullTrace: function () {
        return new Promise((resolve) => {
            scrollyV2.gpxUrl = $('.js-map-scrolly .maps').attr('data-st-gpx');

            if (!scrollyV2.gpxLayer && scrollyV2.gpxUrl) {
                // Création du GPX
                return scrollyV2.gpxLayer = omnivore.gpx(scrollyV2.gpxUrl).on("ready", function (e) {

                    this.eachLayer(function (layer) {
                        if (scrollyV2.coordsTrace.length === 0) {
                            scrollyV2.coordsTrace = layer._latlngs;
                        }
                    });

                    scrollyV2.fullTraceLayer = L.polyline(scrollyV2.coordsTrace, {
                        color: '#000000',
                        weight: 3,
                    }).addTo(scrollyV2.map);

                    scrollyV2.map.fitBounds([scrollyV2.coordsTrace[0]], [scrollyV2.coordsTrace[0]]);

                    resolve();
                })
            }

        });

    },

    handleStickyAnchorsClick: function() {
        document.querySelector('.js-ancre-step-scrolly').addEventListener('click', function(event) {
            if(event.target.hasAttribute('data-scrolly-anchor-id')) {
                scrollyV2.goToStep(event.target.getAttribute('data-scrolly-anchor-id'))
            }
        })
    },

    hideStickyAnchors: function () {
      document.querySelector('.js-ancre-step-scrolly').classList.add('hidden')
    },

    showStickyAnchors: function () {
        document.querySelector('.js-ancre-step-scrolly').classList.remove('hidden')
    },

    initOverlay: function() {
        this.overlay = document.querySelector('.js-step-overlay')
    },

    openOverlay: function (stepId) {
        let content = document.querySelector('.js-section-content[data-step="'+ stepId +'"]')
        this.overlay.querySelector('.js-step-overlay-content').innerHTML = content.innerHTML;
        this.overlay.classList.add('open')
        this.overlay.querySelector('.step-overlay__container').scrollTop = 0
        document.body.classList.add('no-scroll')

        this.initOverlaySliders()
        this.initOverlayAnchorsObserver()
        this.initOverlayAnchorsClick()
        this.initOverlayRelationBlocksObserver()
        this.initRelationSliderMouseover()
    },

    closeOverlay: function (stepId) {
        this.overlay.classList.remove('open')
        document.body.classList.remove('no-scroll')
    },

    initOverlaySliders: function(overlay) {
        let sliders = this.overlay.querySelectorAll('.th-slider:not(.no-autoload)')

        for (let i = 0; i < sliders.length; i++) {
            const relationBlocParent = parents(sliders[i], this.sliderClass)
            let navButtons = null

            if(relationBlocParent.length > 0) {
                navButtons = relationBlocParent[0].querySelectorAll('.nav-buttons button')
            }

            new thSlider(sliders[i], {
                draggable: true,
                scrollListener: true,
                scrollModeMaxWidth: 980,
                oninit: function (slider) {
                    if (navButtons !== null && navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }
                },
                onchange: function (slider) {
                    if (navButtons !== null) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                }
            })
        }
    },

    initOverlayAnchorsObserver : function(overlay) {
        const observer = new IntersectionObserver(handleIntersection);

        function handleIntersection(intersectionEntries) {
            const entry = intersectionEntries[0];
            scrollyV2.overlay.querySelectorAll('.anchor-menu [data-anchor-hash]').forEach(element => element.classList.remove('selected'))
            scrollyV2.overlay.querySelector('.anchor-menu [data-anchor-hash="'+ entry.target.id +'"]').classList.add('selected')
        }

        this.overlay.querySelectorAll('.bloc-ancre').forEach(element=> {
            observer.observe(element);
        })
    },

    initOverlayAnchorsClick : function(overlay) {
        this.overlay.querySelector('.anchor-menu').addEventListener('click', event => {
            if(event.target.hasAttribute('data-goto-anchor')) {
                scrollyV2.overlay.querySelector('#' + event.target.dataset.anchorHash).scrollIntoView({ behavior: "smooth", inline: "nearest" })
            }
        })
    },

    initOverlayRelationBlocksObserver: function () {

        const handleRelationMarkersDisplay = function (intersectionEntries) {

            const entry = intersectionEntries[0];


            if(entry.isIntersecting === true) {
                // display markers

                let markersGroup = []
                entry.target.querySelectorAll('.card').forEach((card, index) => {
                    const caption = card.querySelector('.caption');
                    if (caption) {
                        const letter = caption.getAttribute('data-letter');
                        const icon = caption.querySelector('.fichetype').innerHTML;
                        const sliderCardId = card.getAttribute("data-slide-id");
                        const latLng = {
                            lat: Number(card.dataset.lat),
                            lng: Number(card.dataset.lng)
                        }
                        markersGroup[index] = scrollyV2.createRelationMarker(sliderCardId, latLng, letter, icon)
                        scrollyV2.createRelationMarkerPopup(card.outerHTML, markersGroup[index]);
                    }
                })

                // add fake marker on step coords in order to fit bounds including the step marker position
                markersGroup.push(
                    L.marker(scrollyV2.markers[scrollyV2.lastOpenedPopup].getLatLng(), {
                        icon: L.divIcon(),
                        opacity: 0,
                    })
                )

                scrollyV2.displayedRelationMarkersGroup = L.featureGroup(markersGroup).addTo(scrollyV2.map)

                let flyToOptions = {}
                if(window.innerWidth > 980) {
                    flyToOptions = {
                        paddingBottomRight: [scrollyV2.overlay.offsetWidth, 80],
                        paddingTopLeft: [200, 80],
                        maxZoom: 15}
                } else {
                    flyToOptions = {
                        paddingBottomRight: [40, scrollyV2.overlay.offsetHeight],
                        paddingTopLeft: [40, 40],
                        maxZoom: 14}
                }
                scrollyV2.map.flyToBounds(scrollyV2.displayedRelationMarkersGroup.getBounds(), flyToOptions)


            } else {
                // hide markers
                scrollyV2.removeRelationMarkersGroup()
            }
        }

        const observer = new IntersectionObserver(handleRelationMarkersDisplay)

        this.overlay.querySelectorAll(scrollyV2.sliderClass).forEach(function (el) {
            observer.observe(el);
        })
    },

    removeRelationMarkersGroup: function() {
        if(scrollyV2.displayedRelationMarkersGroup !== null) {
            scrollyV2.map.removeLayer(scrollyV2.displayedRelationMarkersGroup)
            let flyToOptions = {}
            if(window.innerWidth > 980) {
                flyToOptions = {paddingBottomRight: [scrollyV2.overlay.offsetWidth, 0], maxZoom: 15}
            } else {
                flyToOptions = {paddingBottomRight: [0, scrollyV2.overlay.offsetHeight], maxZoom: 14}
            }
            scrollyV2.map.flyToBounds([scrollyV2.coordsTrace[scrollyV2.currentTraceIndex]], [scrollyV2.coordsTrace[scrollyV2.currentTraceIndex]], flyToOptions)
            scrollyV2.displayedRelationMarkersGroup = null
        }
    },

    createRelationMarker: function(markerId, latLng, letter, icon) {
        const iconElement = L.divIcon({
            className: 'blocpush-icon',
            html: "<div class='index-key' data-slide-id-marker='" + markerId + "'>" + letter + "</div><div class='typePicto'>" + icon + "</div>",
            iconSize: ["", ""],
            iconAnchor: [20, 20]
        });

        return L.marker(latLng, {icon: iconElement})
    },

    createRelationMarkerPopup: function(content, marker) {
        const popup = L.popup({className: 'custom-popup-thuria', maxWidth: 240, minWidth: 240}).setContent(content)
        marker.bindPopup(popup);
    },

    initRelationSliderMouseover: function () {
        this.overlay.querySelectorAll(this.sliderClass + ' .card').forEach(card => {
            card.addEventListener('mouseenter', () => {
                document.querySelector('[data-slide-id-marker="'+ card.dataset.slideId +'"]').classList.add('sliderFlash')
            })
            card.addEventListener('mouseleave', () => {
                document.querySelector('[data-slide-id-marker="'+ card.dataset.slideId +'"]').classList.remove('sliderFlash')
            })
        });
    },

    getTraceStepsIntersections: function() {
        /**
         * @todo voir si on peut optimiser pour boucler une seule fois sur les points du tracé plutôt qu'une fois par étape
         * surtout s'il y a une bonne dizaine d'étapes
         */
        let intersections = [];
        let lastIndexTrace = 0;

        scrollyV2.stepsCoordinates.forEach(function (stepCoordinates, stepIndex) {

            scrollyV2.coordsTrace.forEach(function (coordsTraceGpx, indexTrace) {
                const fromLatLng = L.latLng(stepCoordinates.lat, stepCoordinates.lng);
                const toLatLng = L.latLng(coordsTraceGpx.lat, coordsTraceGpx.lng);
                const distance = fromLatLng.distanceTo(toLatLng);

                if (!intersections[stepIndex]) {
                    intersections[stepIndex] = {
                        index: indexTrace,
                        distance: distance
                    };
                } else {
                    if (distance < intersections[stepIndex].distance && (lastIndexTrace < indexTrace || !lastIndexTrace)) {
                        intersections[stepIndex].distance = distance;
                        intersections[stepIndex].index = indexTrace;

                        lastIndexTrace = indexTrace;
                    }
                }
            });
        });

        intersections = intersections.map((step, index) => {
            return {
                index: step.index,
                length: intersections[index + 1] !== undefined ? (intersections[index + 1].index - step.index) : (scrollyV2.coordsTrace.length - step.index),
                distance: step.distance
            }
        });


        return intersections;
    },

    getStepsContentPositions: function() {
        let stepsContentPositions = [];
        document.querySelectorAll(scrollyV2.stepSectionSelector).forEach(function (section) {
            const stepSectionSelectorHeight = section.clientHeight;
            const stepSectionSelectorOffsetTop = section.offsetTop;
            // le bas de la div est égal au top + la hauteur
            const stepSectionSelectorOffsetBottom = stepSectionSelectorOffsetTop + stepSectionSelectorHeight;

            stepsContentPositions.push({
                top: stepSectionSelectorOffsetTop,
                bottom: stepSectionSelectorOffsetBottom,
                height: stepSectionSelectorHeight,
            });
        });

        return stepsContentPositions;
    },

    convertScrollToTraceIndex: function(scrollY, stepsContentPositions, stepsIntersections, stepsStartingPoint) {
        let scrollToTraceIndex = 1;

        for (let i = 0; i < stepsContentPositions.length; i++) {

            if (scrollY > stepsContentPositions[i].top) {

                const percentScrolledInSection = ((scrollY - stepsStartingPoint - stepsContentPositions[i].top) / stepsContentPositions[i].height) * 100;
                if (percentScrolledInSection >= 0) {
                    scrollToTraceIndex = Math.ceil((percentScrolledInSection * stepsIntersections[i].length) * 0.01);

                    let n = i - 1;
                    while(n >= 0) {
                        scrollToTraceIndex += stepsIntersections[n].length
                        n--;
                    }
                }

            }
        }

        return limitNumberWithinRange(scrollToTraceIndex, 1, scrollyV2.coordsTrace.length - 1)
    },

    handleTraceFilling: function (stepsContentPositions, traceSections, stepsContentContainer) {
        let scrollY = window.scrollY; // position dans la page
        let stepsStartingPoint = stepsContentContainer.getBoundingClientRect().top + scrollY;

        if (scrollY >= stepsStartingPoint) {
            const traceIndex = scrollyV2.convertScrollToTraceIndex(scrollY, stepsContentPositions, traceSections, stepsStartingPoint)
            scrollyV2.handleCurrentStepDisplay(traceIndex, traceSections)

            scrollyV2.fillTrace(traceIndex);
            scrollyV2.currentTraceIndex = traceIndex

            if(traceIndex <= 10 && scrollyV2.lastOpenedPopup === 0) {
                scrollyV2.map.flyToBounds([scrollyV2.coordsTrace[traceIndex]], [scrollyV2.coordsTrace[traceIndex]], {
                    animate: true,
                })
            } else {
                scrollyV2.map.fitBounds([scrollyV2.coordsTrace[traceIndex]], [scrollyV2.coordsTrace[traceIndex]]);
            }

            if(window.innerWidth < 980 && (traceIndex >= scrollyV2.coordsTrace.length - 1)) {
                scrollyV2.closePopup(scrollyV2.lastOpenedPopup)
                scrollyV2.lastOpenedPopup = null
            }
        } else {
            scrollyV2.closePopup(scrollyV2.lastOpenedPopup)
            scrollyV2.lastOpenedPopup = null;
            scrollyV2.map.setZoom(14)
        }
    },

    fillTrace: function(traceIndex) {
        let traceCoords = [];

        for(let i = 0; i < traceIndex; i++) {
            traceCoords[i] = [scrollyV2.coordsTrace[i].lat, scrollyV2.coordsTrace[i].lng];
        }

        if(scrollyV2.traceLayer !== null) {
            scrollyV2.map.removeLayer(scrollyV2.traceLayer);
        }

        scrollyV2.traceLayer = L.polyline(traceCoords, {
            color: scrollyV2.layerColor,
            weight: scrollyV2.layerWeight
        }).addTo(scrollyV2.map);
    },

    handleCurrentStepDisplay(traceIndex, traceSections) {
        traceSections.forEach((traceSection, stepIndex) => {
            if(traceIndex >= (traceSection.index - 20) && traceIndex <= (traceSection.index + traceSection.length - 20)) {
                if(scrollyV2.lastOpenedPopup !== stepIndex) {
                    scrollyV2.setCurrentMarkerIcon(stepIndex);
                    scrollyV2.lastOnMarker = scrollyV2.stepsCoordinates[stepIndex];

                    // @todo ajouter l'ouverture d'une mini popup à la place
                    scrollyV2.closePopup(scrollyV2.lastOpenedPopup)
                    // @todo



                    document.querySelectorAll('.js-scrolly-v2-anchors [data-scrolly-anchor-id]').forEach(item => item.classList.remove('active'))
                    document.querySelector('.js-scrolly-v2-anchors [data-scrolly-anchor-id="'+ stepIndex +'"]').classList.add('active')


                    scrollyV2.openPopup(stepIndex)
                }
            }
        });
    }
};


// Utils
function limitNumberWithinRange(num, min = 0, max = 20){
    const parsed = parseInt(num)
    return Math.min(Math.max(parsed, min), max)
}
function parents(el, selector) {
    const parents = [];
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) parents.push(el);
    }
    return parents;
}
