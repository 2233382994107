var std = { // std pour Scrolly-Telling Detail

    // _selector: {
    //     mapContainer: '.map-part',
    // },
    markerIcon:'active-st',
    etapeIcon:'scrolly',
    map:null,
    mainMarker:false,
    gpxLayer:false,
    gpxUrl:false,
    gpxColor:false,
    gpxBounds:false,
    gpxMarkerSite: 'site_velo',
    gpxData:{
        coordLat: [],
        coordLng: [],
        coordAlt: []
    },
    etapes:[],
    markerEtape:[],
    chart:false,
    // scroll
    arrayIndexEtapes: [],
    coordsTrace : [],
    indexCanvasPointTop: [],
    heightClient : document.body.clientHeight,
    popup: L.popup(),
    // ancien code ?
    // ticking : false,
    // arrayWithEtapes:[],
    // arrayEtapesPxl: [],
    // totalPxlEtapes : 0,
    // scrollPrecedent : 0,
    // latlngsTotal: 0,
    //  / ancien code ?


    init: function(carte){
        var site = $('.maps').attr('data-kml-site');
        if (site){
            std.gpxMarkerSite = site;
        }

        std.map = carte;
        std.loadEtapes();
        std.placeGpx();
        std.switchMapSize();
        // open-close-map
        // if (window.location.href.indexOf("?checklatlng") > -1) {
        //     std.map.on('click', std.onMapClick);
        // }

    },

    // onMapClick :function(e){
    //     std.popup
    //         .setLatLng(e.latlng)
    //         .setContent( e.latlng.toString())
    //         .openOn(std.map);
    // },

    loadEtapes : function(){
        var i = 0;
        $(".etape").each(function(){
            var lat = $(this).data("lat");
            var lng = $(this).data("lng");
            var index = $(this).data("index");
            std.etapes.push({
                // "title" : $(this).find(".titre").text(),
                "lat" : lat,
                "lng" : lng,
            });
            if (index > 0){ // on n'inclu pas dans la carte le point de départ
                console.log(std.etapeIcon+'-'+std.gpxMarkerSite+'-'+index);
                std.markerEtape.push(th_maps.createMarker(std.map,{lat:lat,lng:lng},std.etapeIcon+'-'+std.gpxMarkerSite+'-'+index));
            }


            std.indexCanvasPointTop[i] = $("#etape-"+i).offset().top;
            i++;
        });

    },

    /** Affichache du tracé GPX **/
    placeGpx: function(){
        std.gpxUrl = $('.maps').attr('data-st-gpx');
        std.gpxColor = $('.maps').attr('data-kml-color');
        if (!std.gpxLayer && std.gpxUrl){

            // Création du GPX
            std.gpxLayer = omnivore.gpx(std.gpxUrl).on("ready", function(e) {
                var highlightStyle = {
                    weight: 3,
                    opacity: 1,
                    color: "black",
                    dashArray:"3,6"
                };
                this.setStyle(highlightStyle);

                this.eachLayer(function(layer) { // parcours de chaque layers car la clé du layer diffère a chaque chargement-
                    if (std.coordsTrace.length === 0){
                        std.coordsTrace = layer._latlngs;
                    }
                });

                scrolly.initScroll(std.coordsTrace, std.etapes, std.map);
                std.arrayIndexEtapes = std.totalArrayEtapes([std.coordsTrace], std.etapes);

                std.map.fitBounds(e.target.getBounds());

            }).addTo(std.map);
        }
    },

    mapMarkerClick : function(e){
        //evenement quand on clic sur un point de la map
        for(i = 0; i < std.markerEtape.length; i++){
            if(e.latlng == std.markerEtape[i]._latlng){

                $('html, body').animate({scrollTop:std.indexCanvasPointTop[i+1] - (std.heightClient/2)+2},'slow');
                //force zoom

                let lat = std.markerEtape[i]._latlng.lat;
                let lng = std.markerEtape[i]._latlng.lng;

                std.map.setZoomAround(std.coordsTrace[std.arrayIndexEtapes[i+1][0]], 17, true);

                setTimeout(function(){
                    std.map.setView([lat, lng], 17);
                },800);
            }
        }
    },


    calcPointsLesPlusProches: function(array, arrayCibles){

        notes = [];
        pointsLesPlusProches = [];
        for(j = 0; j < arrayCibles.length; j++){
            notes[j] = 1000;
        }
        for(i = 0; i < array.length; i++){

            for(k = 0; k < j; k++){
                lat = Math.abs(array[i]['lat'] - arrayCibles[k][0])
                lng = Math.abs(array[i]['lng'] - arrayCibles[k][1])
                total = lat + lng;

                if(total < notes[k]){
                    notes[k] = total
                    pointsLesPlusProches[k] = [i, array[i]];
                }
            }
        }

        return pointsLesPlusProches;
    },

    totalArrayEtapes: function(array, etapes){
        arrayCibles = [];

        for(i = 0; i < etapes.length; i++){
            arrayCibles[i] = [etapes[i]["lat"], etapes[i]["lng"]]
        }

        pointsLesPlusProches = std.calcPointsLesPlusProches(array[0], arrayCibles);

        resp = [];
        for(i = 0; i < pointsLesPlusProches.length; i++){
            if(i != pointsLesPlusProches.length-1){
                resp[i] = [pointsLesPlusProches[i][0], pointsLesPlusProches[i+1][0]-1]
            } else {
                // derniere etape
                resp[i] = [pointsLesPlusProches[i][0], array[0].length]
            }
        }

        return resp
    },

    switchMapSize: function(){
        $('.open-close-map').on("click",function(){
            $(this).parent().toggleClass("open");

            setTimeout(function(){
                std.map.invalidateSize();
                if (std.gpxLayer){
                    var bounds = std.gpxLayer.getBounds();
                }else{
                    var bounds = std.mainMarker.getBounds();
                }
                std.map.fitBounds(bounds);
            },400);
        });
    },



};