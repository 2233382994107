var geoloc = {

    lastGeoLock: null,

    canGeo: function(){
        if(navigator.geolocation) {
            return true;
        } else {
            return false;
        }
    },

    getGeoloc: function(callback){
        if(geoloc.lastGeoLock){
            callback(geoloc.lastGeoLock);
        }

        navigator.geolocation.getCurrentPosition(function(position){
            geoloc.lastGeoLock = {};
            geoloc.lastGeoLock.lat = position.coords.latitude;
            geoloc.lastGeoLock.lng = position.coords.longitude;

            callback(geoloc.lastGeoLock);

        },geoloc.erreur,{maximumAge:60000});
    },

    closeGeoloc: function(){
        geoloc.lastGeoLock = null;
    },

    erreur: function(error){
        var info = thLang.get('geoloc_error_base');
        switch(error.code) {
            case error.TIMEOUT:
                info += thLang.get('geoloc_error_1');
                break;
            case error.PERMISSION_DENIED:
                info += thLang.get('geoloc_error_2');
                break;
            case error.POSITION_UNAVAILABLE:
                info += thLang.get('geoloc_error_3');
                break;
            case error.UNKNOWN_ERROR:
                info += thLang.get('geoloc_error_unknow');
                break;
        }
        alert(info);
    }


};