var stpi = {
    init: function(){
    },


    pushIconeBSP: function(map, etape) {
        if(etape > 0){
            this.removeIconeBSP(map);
        }
        let n=1;
        $('.bloc-relation-scrolly').each(function () {

            $(".card",this).each(function(e){
                var lat = $(this).data("lat")*1;
                var lng = $(this).data("lng")*1;

                if (lat && lng){
                    var icon = L.divIcon({
                        className: 'blocpush-icon',
                        html: "<span class='index-key'>"+n+"</span>",
                        iconSize: [30, 30],
                        iconAnchor: [15, 15]
                    });

                    th_maps.createMarker(map ,{lat:lat,lng:lng},icon);
                    n++;
                }
            });
        });
    },

    removeIconeBSP: function(carte){
        carte.eachLayer(function (layer) {
            if (layer.editing && layer.editing._marker){
                var markerElem = $(layer._icon);
                if (markerElem.hasClass("blocpush-icon")){
                    carte.removeLayer(layer);
                }
            }
        });
    }


}


stpi.init();