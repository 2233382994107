var thBlocs = {

    toInit: [],

    init:function(){

        debug('INIT : thBlocs');

        thPWA.callbacks.thblock = thBlocs.initAll;
    },

    initAll: function(){

        debug('INIT ALL : thBlocs');

        $.each(thBlocs.toInit,function(k,blocMethodName){
           thBlocs[blocMethodName]();
        });
    },

    add: function(name,myFunction,doNotInitNow){
        thBlocs[name] = myFunction;
        thBlocs.toInit.push(name);
        if(doNotInitNow != true){
            thBlocs[name]();
        }
    }

};

thBlocs.init();
