thBlocs.add('allneedredo',function(){

    debug('INIT : global need redo');

    var pwaContent = $('#pwa-content').get(0);
    // Initialisation des zoombox
    $('.zoombox',pwaContent).zoombox();

    // lancement du script de retro compatibilité des images en object fit
    objectFitImages('.fit-cover img');

});