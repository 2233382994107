function isIE(){
    if (navigator.userAgent.match(/trident/gi) || navigator.appName == 'Microsoft Internet Explorer') {
        return true;
    }
    return false;
}

function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}

function isNoHover(){
    if(isTouchDevice() && $(window).width() <= thConfig.tabletPaysageBreakpoint){
        return true;
    }
    return false;
}

function isTabletPortraitOrSmalller(){
    if(document.body.clientWidth < thConfig.tabletPortraitBreakpoint){
        return true;
    }
    return false;
}

function isMobileOrSmalller(){
    if(document.body.clientWidth < thConfig.mobileBreakpoint){
        return true;
    }
    return false;
}

function current_href() {
  var splitedHref = document.location.href.split("#");
  return splitedHref[0];
}


if (isIE()) {
    $('body').addClass('ie');
}

if (isNoHover()) {
    $('body').addClass('no-hover');
}

if (isTouchDevice()) {
    $('.lang .sub-menu').addClass('sub-lang-mobile');
    $('.lang > .lang-mobile').addClass('is-display');
};


// affichage des overlays possible que a partie de 2s
setTimeout(function(){
    $('body').addClass('overlay-load');
},1000);